import MorphDomOptions from 'Browser/pages/MorphDomOptions';
import morphdom from 'morphdom';
import {IPage} from 'Common/pages/IPage';
import {Component} from 'Common/components/Component';
import {CreateComponent} from 'Common/components/CreateComponent';
import {DeleteComponent} from 'Common/components/DeleteComponent';
import Assert from 'Common/Assert';
import {EditableArray} from 'Common/components/EditableArray';
import {IPageData} from 'Common/PageConfig';
import {IPageWrapper} from 'Browser/pages/PageWrapper';
import {Location} from 'Common/config/PageConfigTypes';

export class NunjucksPageWrapper implements IPageWrapper
{
	constructor(
		public page: IPage<IPageData>
	)
	{
		this.display = this.display.bind(this);
		this.refresh = this.refresh.bind(this);
		this.doDisplay = this.doDisplay.bind(this);
		this.createAndRedirect = this.createAndRedirect.bind(this);
		this.createItemAndRedirect = this.createItemAndRedirect.bind(this);
		this.confirmAndDelete = this.confirmAndDelete.bind(this);
		this.confirmAndDeleteItem = this.confirmAndDeleteItem.bind(this);
	}

	async display()
	{
//XXX display() could/possibly should avoid using morphdom. Then again, headers and footers will often be shared between pages
//XXX an alternative to calling display() OR refresh() might be to store the current page name on the HTML page somewhere and to start
//    by querying it to see if it has changed.

		await this.doDisplay(true);
	}

	async refresh():Promise<void>
	{
		await this.doDisplay(false);
	}

	private async doDisplay(firstCall:boolean)
	{
		const {title,bodyContent} = await this.page.renderPartsToStrings();

		const root = Assert.htmlElement(document.getElementById('root'));
		const e = document.createElement('div');
		e.innerHTML = bodyContent;

		/* 
			Note morphdom does not run embedded scripts by default. Should we need it there is
			a workaround here:  https://github.com/patrick-steele-idem/morphdom/issues/178

		 */
		morphdom(root,e,this.displayDiffOptions());
		root.id = 'root';

		/* Using this line to ensure document.title exists */
		document.title = title ?? '';
		/* Now overwrite title in order to cope with any HTML entities (eg quotation marks) */
		document.querySelector('title')!.innerHTML = title ?? '';

//XXX probably better to replace these postDisplay() & callAfterDisplay() calls with a more generalised listener/observer
//    mechanism that requires the observed to add itself. The observer could be anything - a page, component, widget, ...
		for (const component of this.page.components()) 
			component.postDisplay();

		/* Note that callInitInstances() is called by MorphDOM callbacks */					
		for (const widget of this.page.widgets(this)) 
			widget.callAfterDisplay(document.documentElement);
	}

	postFirstDisplayInit()
	{
//XXX this does not appear to be being called in display()... why?
		for (const widget of this.page.widgets(this)) 
			widget.callInitInstances(document.documentElement);

//XXX THIS IS CAUSING LOAD PROBLEM...
		for (const component of this.page.components())
			component.postDisplay();

		for (const widget of this.page.widgets(this)) 
			widget.callAfterDisplay(document.documentElement);
	}

	/* Options used in the DOM-diffing by MorphDOM */
	private displayDiffOptions(): MorphDomOptions
	{
		return {
			getNodeKey: (node:Node) => {
				if (!(node instanceof HTMLElement))
					return null;

				/*
					The docs say: "Note that form fields must not have a name corresponding to forms' 
					DOM properties, e.g. id". Don't really know what this means...

					I've copied most of this implementation from MorphDOM.

					The plan is to use 'data-key="..."' in those cases where something just needs to be
					added to prevent confusion during patching. That way "id" can be used for proper things.
				 */

				if ('dataset' in node && 'key' in node.dataset) 
					return node.dataset.key;
				return (node.getAttribute && node.getAttribute('id')) || node.id;
			},
			onBeforeElUpdated: (fromEl:Node,toEl:Node) => {
				if (!(fromEl instanceof HTMLElement))
					return true;
				for (const widget of this.page.widgets(this))
					if (!widget.beforeUpdate(fromEl,Assert.htmlElement(toEl)))
						return false;

				return true;
			},
			onElUpdated: (node:Node) => {
				if (!(node instanceof HTMLElement))
					return true;
				for (const widget of this.page.widgets(this))
					widget.afterUpdate(node);
			},
			/* This function is called on all subnodes, including text nodes */
			onNodeAdded: (node:Node) => {
				if (!(node instanceof HTMLElement))
					return node;

				/*
					CAUTION: In most cases we get an 'onNodeAdded' call for each potential anchor node, but this seems not
							 to be the case when a repeater item is expanded and an existing item is already expanded.
							 To work around this particular case I'm closing the first item, redisplaying, then openning the second
							 item and redisplaying.       [NB may be able to use afterUpdate() instead which I've just included...]
				 */
				for (const widget of this.page.widgets(this)) {
					if (widget.isAnchorNode(node))
						widget.initInstance(node);
					widget.afterAdded(node);
				}

				/* This return value isn't documented. Don't know what its meant to be. */
				return node;
			},

//TODO for repeaters if a collapse happens call destroyInstances on all contained widgets.  NB if too sluggish could potentially reuse some - but state issues may arise				

			/* This is called once, on the topmost node. Not on children. */
			onBeforeNodeDiscarded: (node:Node) => {
				if (!(node instanceof HTMLElement))
					return true;

				for (const widget of this.page.widgets(this))
					if (!widget.beforeRemove(node))
						return false;
				for (const widget of this.page.widgets(this)) 
					if (widget.isAnchorNode(node))
						widget.destroyInstances(node);
				return true;
			}
		};
	}

	/* Can be called during cleanup */
	leave()
	{
		for (const widget of this.page.widgets(this))
			widget.destroyAll();
//XXX perhaps should call component.leave() by default 
	}

	private evaluateRedirect(comp:Component,location:Location) 
	{
		Assert.exists(this.page.data);

		const def = (<any>comp).config;

		if (def?.redirect!=undefined) 
			document.location.href = def.redirect(location, this.page.data);
	}

//XXX Possibly put into CreateComponent or Page 
	public async createAndRedirect(compName:string,location:Location)
	{
		const comp = Assert.child(CreateComponent,this.page.component(compName));
		await comp.create();
		this.evaluateRedirect(comp,location);
	}

	public async createItemAndRedirect(compName:string,location:Location)
	{
		const comp = Assert.child(EditableArray,this.page.component(compName));
		await comp.addItem(location,true);
		this.evaluateRedirect(comp,location);
	}

//XXX location
	public async confirmAndDelete(compName:string,location:Location)
	{
		if (!confirm('Are you sure you wish to delete this item?'))
			return;

		const comp = Assert.child(DeleteComponent,this.page.component(compName));
		this.evaluateRedirect(comp,location);
		await comp.delete(); 
	}

	public async confirmAndDeleteItem(compName:string,location:Location,index:number)
	{
		if (!confirm('Are you sure you wish to delete this item?'))
			return;

		const comp = <EditableArray<IPageData,any>>this.page.component(compName);
		await comp.deleteItem(location,index);
	}

	/* Used in njk files */
	public async goTo(route:string)
	{
		location.href = route;
	}
}



