import {VenueRoutes as SharedRoutes, VenueRoutesType} from 'Shared/routes/VenueRoutes';
import {Menu as FrontendMenu} from 'Shared/view/frontend/Menu';
import {Members as FrontendMembers} from 'Shared/view/frontend/Members';

export class TheTownieRoutes extends SharedRoutes
{
	frontend()
	{
		return <VenueRoutesType>({
			...super.frontend(),

			//FIXME replace these with redirects (need to reimplement these as a possibility)

			'/members':		[FrontendMembers, () => ({})],

			/* Printed QR codes link to this */
			'/clubtownie':	[FrontendMembers, () => ({})],

			/* The townies Google profile has their menu linked to townhallhotelnewtown.com/food/ (the slash is required) */
			'/food':		[FrontendMenu,() => ({})],
		});
	}
}

