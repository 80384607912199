import {VenueRoutes, VenueRoutesType} from 'Shared/routes/VenueRoutes';


//TODO delete this later if no special routes or pages required

export class IndieIslandRoutes extends VenueRoutes
{
	frontend()
	{
		return <VenueRoutesType> ({
			...super.frontend(),
//E.g.		'/menu':		[FrontendMenu, () => ({})],
		});
	}
}

