import {NjkEditComponent} from 'Common/components/NjkEditComponent';
import {EditKeyComponent} from 'Common/components/EditKeyComponent';
import {CreateComponent} from 'Common/components/CreateComponent';
import {DeleteComponent} from 'Common/components/DeleteComponent';
import {Tabs} from 'Common/components/Tabs';
import {Breadcrumbs} from 'Common/Breadcrumbs';
import {Component} from 'Common/components/Component';
import {ExtraDataComponent} from 'Browser/ExtraDataComponent';
import {OverlayComponent} from 'Browser/OverlayComponent';
import {AnyComponent} from 'Common/config/PageConfigTypes';
import Assert from 'Common/Assert';
import {SiteBasics} from 'Common/SiteBasics';
import {IConnectionToServer} from 'Common/IConnectionToServer';
import {EditableArray} from 'Common/components/EditableArray';
import {ImageUploaderComponent} from 'Common/components/ImageUpoaderComponent';
import {Document,IPageData} from 'Common/PageConfig';
import {EditableDocumentList} from 'Common/components/EditableDocumentList';
import {IPage} from 'Common/pages/IPage';
import {RepeaterComponent} from 'Common/components/RepeaterComponent';
import {EditComponent} from 'Common/components/EditComponent';


export class ComponentCreator<Doc extends Document>
{
	constructor(
		private site:SiteBasics, //XXX used for Links which is closely related to Urls. Share somehow and remove site here?
		private server:IConnectionToServer,
		private page: IPage<IPageData>
	)
	{ }

	public createComponent(name:string,def:AnyComponent<IPageData,Doc>):Component
	{
		const compData = this.page.data[name];

		if (def.type=='create') 
			return new CreateComponent(name,this.server,this.page,compData,def.initialValues,def.redirect);

		if (def.type=='edit') { 
			const doc = this.page.data[def.collection]; 
			return new NjkEditComponent(name,this.page,this.server,doc,def);
		}

		if (def.type=='edit2') { 
			const doc = this.page.data[def.collection]; 
			return new EditComponent(name,this.page,this.server,doc,def);
		}

		if (def.type=='editKey') { 
			const doc = this.page.data[def.collection]; 
			return new EditKeyComponent(name,this.page,this.server,doc,def);
		}
		if (def.type=='delete') {
			const docId = this.page.data[def.collection]._id; 
			return new DeleteComponent(name,this.page,this.server,def,docId);
		}

		if (def.type=='editArray') {
			const documentData = this.page.data[def.collection]; 
			const docId = documentData._id;
			return new EditableArray(this.server,this.page,name,def,documentData,docId,compData);
		}

		if (def.type=='repeater') {
//TODO cf just passing page through and getting compData and documentData there instead. 
//  Might this cause problems in the operations?
// Also cf editArray and edit components...
			const documentData = this.page.data[def.collection]; 
			return new RepeaterComponent(name,this.page,this.server,documentData,def,compData  /* ,def.locateParams(this.page.data)*/);
		}

		if (def.type=='editDocumentList') 
			return new EditableDocumentList(this.page,name,def);

		if (def.type=='tabs')
			return new Tabs(name,def,this.page.data);

		if (def.type=='breadcrumbs') 
			return new Breadcrumbs(name,this.site,this.page,def,compData);

		if (def.type=='extraData')
			return new ExtraDataComponent(name,compData);

		if (def.type=='overlay')
			return new OverlayComponent(name,def,compData);

		if (def.type=='imageUploader') 
			return new ImageUploaderComponent(name,this.server,this.page.name(),def,this.page.data[def.collection]);

		Assert.check(false);
	}
}

