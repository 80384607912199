
//XXX cf separating out ratio? or having width & height for non-full?

import {Image} from 'Common/config/Fields';


/* This is an A4 portrait + a date header */
export const eventPoster:Image = {
	full: {minWidth:528,minHeight:866,formats:['jpeg','png']},
	medium: {ratio:0.61,width:528,format:'jpeg'},
	tiny: {ratio:0.61,width:100,format:'jpeg'},
};

export const a4Portrait:Image = {
	full: {ratio:0.7071,minWidth:800,formats:['jpeg','png']},
	large: {ratio:0.7071,width:1200,format:'jpeg'},
	medium: {ratio:0.7071,width:600,format:'jpeg'},
	small: {ratio:0.7071,width:400,format:'jpeg'},
	tiny: {ratio:0.7071,width:100,format:'jpeg'}
};

//CJ added photoPortrait 4x5 ratio so we can keep VOS posters at total ratio of A4 including date bar
export const portrait4x5:Image = {
	full: {ratio:0.80,minWidth:800,formats:['jpeg','png']},
	large: {ratio:0.80,width:1200,format:'jpeg'},
	medium: {ratio:0.80,width:600,format:'jpeg'},
	small: {ratio:0.80,width:400,format:'jpeg'},
	tiny: {ratio:0.80,width:100,format:'jpeg'}
};

export const squareAllSizes:Image = {
	full: {ratio:1,minWidth:800,formats:['jpeg','png']},
	large: {ratio:1,width:1200,format:'jpeg'},
	medium: {ratio:1,width:600,format:'jpeg'},
	small: {ratio:1,width:400,format:'jpeg'},
	tiny: {ratio:1,width:100,format:'jpeg'}
};

export const landscapeThreeTwoAllSizes:Image = {
	full: {ratio:1.5,minWidth:800,formats:['jpeg','png']},
	large: {ratio:1.5,width:1200,format:'jpeg'},
	medium: {ratio:1.5,width:600,format:'jpeg'},
	small: {ratio:1.5,width:400,format:'jpeg'},
	tiny: {ratio:1.5,width:100,format:'jpeg'}
};

export const landscape3x2Large:Image = {
	full: {ratio:1.5,minWidth:800,formats:['jpeg','png']},
	large: {ratio:1.5,width:1200,format:'jpeg'},
};

export const facebookBanner:Image = {
	full: {ratio:1.91,minWidth:800,formats:['jpeg','png']},
	large: {ratio:1.91,width:1200,format:'jpeg'},
	medium: {ratio:1.91,width:600,format:'jpeg'},
	small: {ratio:1.91,width:400,format:'jpeg'},
	tiny: {ratio:1.91,width:100,format:'jpeg'}
};

//IS this ratio around the wrong way? how can landscapeThreeTwo above = ratio 1.5 but this profile3x2 = ratio:0.6667
export const profile3x2:Image = {
	full: {ratio:0.6667,minWidth:600,formats:['jpeg','png']},
	large: {ratio:0.6667,width:800,format:'jpeg'},
	medium: {ratio:0.6667,width:400,format:'jpeg'},
	small: {ratio:0.6667,width:200,format:'jpeg'},
	tiny: {ratio:0.6667,width:100,format:'jpeg'}
};


export const venueLogo:Image = {

	full: {ratio:1,minWidth:500,formats:['jpeg','png']}
};

export const venuePhoto:Image = {
	full: {ratio:1.5,minWidth:800,formats:['jpeg','png']}
};

