import {ImageUploadWidget} from 'Browser/widgets/ImageUploadWidget';
import {Components,DocumentQueries, ImageUploaderComponent} from 'Common/config/PageConfigTypes';
import {PageFunctions} from 'Browser/PageFunctions';
import { z } from 'zod';
import {special as specialsModel,Special as SpecialsDoc} from 'Shared/model/Specials';
import * as t from 'Shared/model/basic';
import * as imageSize from 'Shared/model/imageSize';
import {HtmlEditorWidget} from 'Browser/widgets/HtmlEditorWidget';
import {CalendarEntryWidget} from 'Browser/widgets/CalendarEntryWidget';
import {DateTimeWidget} from 'Browser/widgets/DateTimeWidget';
import {MultiSelectWidget} from 'Browser/widgets/MultiSelectWidget';
import {Specials} from 'Shared/view/backend/Specials';
import {IPageWrapper} from 'Browser/pages/PageWrapper';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {BackendPageConfig, backendData, backendParams} from 'Common/BackendPageConfig';
import {VenueUrls} from 'Common/VenueUrls';


export const specialParams = backendParams.extend({
	id: t.id
}).strict();	
export type SpecialParams = z.infer<typeof specialParams>;


export const specialData = backendData.extend({
	params: specialParams,
	special: specialsModel,
}).strict();
export type SpecialData = z.infer<typeof specialData>;



export class Special extends BackendPageConfig<SpecialData,SpecialParams>
{
	static readonly pageName = 'backend/special';
	name() { return Special.pageName; }

	access() { return 'venueMenuEditor' as AccessAreaName; }

	widgets(pageWrapper:IPageWrapper)
	{

		const urls = new VenueUrls(this.build,this.venue.key);
		
		
		return {
			[ImageUploadWidget.key()]: new ImageUploadWidget(pageWrapper),
			[HtmlEditorWidget.key()]: new HtmlEditorWidget(urls),
			[CalendarEntryWidget.key()]: new CalendarEntryWidget(pageWrapper),
			[DateTimeWidget.key()]: new DateTimeWidget(),
			[MultiSelectWidget.key()]: new MultiSelectWidget(),
		};
	}

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			htmlClasses: `${this.htmlClasses} special`,
			template: 'App/backend/specials/specialPage.njk'
		};
	}

	documents(params:SpecialParams): DocumentQueries
	{
		return ({
			...super.documents(params),
			special: {
				type:'object',
				collection: 'special',
				aggregate: () => [
					{$match: {_id: params.id}}
				]
			}
		});
	}

	components(): Components<SpecialData>
	{
		return ({
			breadcrumbs: {
				type: 'breadcrumbs',
				parent: {page:Specials.pageName,component:'breadcrumbs'},
				link: (data:SpecialData) => '/admin/special/'+data.params.id
			},
			editSpecial: {
				type:'edit',
				collection: 'special',
				locate: (data:SpecialsDoc) => data
			},

			deleteSpecial: {
				type: 'delete',
				collection: 'special',
				redirect: () => `/admin/specials`
			},

			editTimes: {
				type:'edit',
				collection: 'special',
				locate: (doc:SpecialsDoc) => doc.times,
			},

			poster: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'special',
				field: 'image',
				locate: (data:Special) => data,
				assetFolder: 'Special-image',
				sizes: imageSize.a4Portrait,
				previewSize: 'medium'
			},

			//TODO try creating a wrapper method eg ...imageUploaderWithAspectSelector(****)    [ALT: separate component]. wrapper is lightweight
			/* NOTE: these components are using the same DB field and assets folder: */
			//XXX ==> landscapeImage etc			
			landscapePhoto: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'special',
				field: 'image',
				locate: (doc:SpecialsDoc) => doc,
				assetFolder: 'Special-image',
				sizes: imageSize.landscapeThreeTwoAllSizes, 
				previewSize: 'medium'
			},
			squarePhoto: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'special',
				field: 'image',
				locate: (doc:SpecialsDoc) => doc,
				assetFolder: 'Special-image',
				sizes: imageSize.squareAllSizes, 
				previewSize: 'medium'
			},
			portraitPhoto: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'special',
				field: 'image',
				locate: (doc:SpecialsDoc) => doc,
				assetFolder: 'Special-image',
				sizes: imageSize.portrait4x5,
				previewSize: 'medium'
			},
			suppliedPoster: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'special',
				field: 'image',
				locate: (doc:SpecialsDoc) => doc,
				assetFolder: 'Special-image',
				sizes: imageSize.a4Portrait,
				previewSize: 'medium'
			},

			outerTabs: {
				type: 'tabs',
				names: ['details','marketing'],
//				initial: (data:SpecialData) => data.params.topTab ?? 'details'
				initial: () => 'details'
			},
			//webpage added in case we need in future
			previewTabs: {
				type: 'tabs',
				names:['poster','webpage'],
//				initial: (data:SpecialData) => data.params.secondTab ?? 'poster'
				initial: () => 'poster'
			},
		});
	}

	beforeDisplay(data:SpecialData)
	{
		super.beforeDisplay(data);

		const funcs = new PageFunctions();
		const urls = new VenueUrls(this.build,this.venue.key);

		if (data.special.image==undefined) 
			data.special.image = <any>{};

		funcs.imageUploadUrl(urls,data.special._id,Special.pageName,'editSpecial',data.special.image!,'Special-image','special',[data.params.id],'medium','defaults/profile2x3.png');
		data.special.image!.url = funcs.createImageUrl(urls,data.special.image,'Special-image','medium');



/*TODO
		{function: "updateLocationWithTabs","arg": {
			"ifany": [
			[
				["!=", { "path": "outerTabs.selected" }, "details"],
				{ "fill": "/admin/special/{{params.id}}/{{outerTabs.selected}}" }
			],
			[
				["=", { "path": "previewTabs.selected" }, "poster"],
				{ "fill": "/admin/special/{{params.id}}/details/poster" }
			]
			],
			"else": {
			"fill": "/admin/special/{{params.id}}/details/{{previewTabs.selected}}"     
			}
		} }
*/		
	}
}

