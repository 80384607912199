import {Component} from 'Common/components/Component';
import {CreateMessage} from 'Common/Messages';
import {IPage} from 'Common/pages/IPage';
import {IConnectionToServer} from 'Common/IConnectionToServer';
import {CreateComponent as CreateComponentConfig} from 'Common/config/PageConfigTypes';
import {Id} from 'Common/Id';
import { Url } from 'Common/config/PageConfigTypes';
import {IPageData} from 'Common/PageConfig';

/*
	Creates a single row for a single table.
 */
export class CreateComponent<PageData extends IPageData> extends Component
{
	constructor(
		private instanceName:string,
		private server:IConnectionToServer,
		private page:IPage<PageData>,
		private data:{id?:Id},
//		private data:PageData,
		private initialValues?: (data:PageData) => {[name:string]:any},
		private redirect?: (data:PageData) => Url
	)
	{
		super(instanceName);

		this.create = this.create.bind(this);
	}

	componentType() { return 'create'; }

	async load()
	{
		return {};
	}


	/* fields may be an empty object */
	async create()
	{
		const initial = this.initialValues!=undefined ? this.initialValues(this.page.data) : {};

		const msg = new CreateMessage(this.page.name(),this.instanceName,initial);

console.log('CreateComponent create() BEFORE this.page.data:',this.page.data);
        const {id} = await this.server.sendOperation(msg);
console.log('CreateComponent create() id:',id);  
		this.data.id = id;
console.log('CreateComponent create() data:',this.data);
console.log('CreateComponent create() AFTER this.page.data:',this.page.data);

		if (this.redirect!=undefined)
			location.href = this.redirect(this.page.data);
	}
}

