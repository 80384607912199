import {Component} from 'Common/components/Component';
import {OverlayComponent as ShowConfig} from 'Common/config/PageConfigTypes';

export class OverlayComponent extends Component
{
	constructor(
		instanceName:string,
		private config:ShowConfig,
		private localData:{[pane:string]:boolean} 
	)
	{
		super(instanceName);
		this.toggle = this.toggle.bind(this);
	}

    public componentType():string { return 'overlay'; }

	async load()
	{
		return {...this.config.panes};
	}

	public toggle(fieldName:string)
	{
		this.localData[fieldName] = ! this.localData[fieldName];
	}


//FIXME? it used to be that PageRenderer woud poll all components for classes to add to the root or body node
//       or something. Probably better to take a more direct method and add it to the page or something.
//       Also, is this more of a widget thing? Or maybe add to postDisplay()?
	public htmlClasses():Set<string> 
	{ 
		const classes:Set<string> = new Set();

		for (const item of Object.values(this.localData)) 
			if (item) {
				classes.add('overlayed');
				break;
			}

		return classes;
	} 
}
