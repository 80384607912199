import { z } from 'zod';
import {Components,DocumentQueries, ImageUploaderComponent, Location} from 'Common/config/PageConfigTypes';
import * as imageSize from 'Shared/model/imageSize';
import {ImageUploadWidget} from 'Browser/widgets/ImageUploadWidget';
import {PageFunctions} from 'Browser/PageFunctions';
import Assert from 'Common/Assert';
import {menu as menuModel,Menu as MenuDoc} from 'Shared/model/Menu';
import {MultiSelectWidget} from 'Browser/widgets/MultiSelectWidget';
import {Course} from 'Shared/view/backend/Course';
import {IPageWrapper} from 'Browser/pages/PageWrapper';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {BackendPageConfig, backendData, backendParams} from 'Common/BackendPageConfig';
import {VenueUrls} from 'Common/VenueUrls';

export const menuItemParams = backendParams.extend({
	course: z.string(),
	index0: z.number()
}).strict();	
export type MenuItemParams = z.infer<typeof menuItemParams>;


export const menuItemData = backendData.extend({
	params: menuItemParams,
	menu: menuModel,
}).strict();
export type MenuItemData = z.infer<typeof menuItemData>;


export class MenuItem extends BackendPageConfig<MenuItemData,MenuItemParams>
{
	static readonly pageName = 'backend/menuItem';
	name() { return MenuItem.pageName; }

	access() { return 'venueMenuEditor' as AccessAreaName; }

	widgets(page:IPageWrapper)
	{
		return {
			[ImageUploadWidget.key()]: new ImageUploadWidget(page),
			[MultiSelectWidget.key()]: new MultiSelectWidget(),
		};
	}

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			htmlClasses: `${this.htmlClasses} menuItem`,
			template: 'App/backend/menu/menuItemPage.njk'
		};
	}

	documents(params:MenuItemParams): DocumentQueries
	{
		return ({
			...super.documents(params),
			menu: {
				type:'object',
				collection: 'menu'
			}
		});
	}

//TODO just instantiate Components and Documents here directly?
//TODO think remove urls and transforms from constructor	

	components(): Components<MenuItemData>
	{
		return ({
			breadcrumbs: {
				type: 'breadcrumbs',
				parent: {page:Course.pageName,component:'breadcrumbs'},
				link: (data:MenuItemData) => '/admin/menu/'+data.params.course+'/'+data.params.index0  //TODO? move this code into Links file
			},
			editItem: {
				type:'edit',
				collection: 'menu',
				locate: (data:MenuDoc,params:{course:string,index:number}) => 
					Assert.have(data.courses[params.course][params.index])
			},

			deleteItem: {
				type: 'editArray',		//XXX COULD be split into addItem, deleteItem?
				collection: 'menu',
				selector: '#unused',
				locateListParent: (doc:MenuDoc) => doc.courses,
				fieldName: (location:Location) => Assert.toString(location[0]),
				redirect: () => `/admin/menu`
			},

			prices: {
				type: 'repeater',
				selector: '#prices repeater-items',
				collection: 'menu',
				locate: (doc:MenuDoc,location:Location)				=> doc.courses[location[0]][Assert.toNumber(location[1])].prices![Assert.toNumber(location[2])],
				locateListParent: (doc:MenuDoc,location:Location)	=> doc.courses[location[0]][Assert.toNumber(location[1])],
				fieldName: () => 'prices',
				initialValues: () => ({}),
				processInput: (location:Location,field:string,value:string) => value,	
			},

//XXX ==> tabs			
			outerTabs : {
				type: 'tabs',
				names: ['details','marketing'],
				initial: () => 'details'
			},
			previewTabs : {
				type: 'tabs',
				names: ['summary','full'],
				initial: () => 'summary'
			},
//TODO combine these components somehow I think...			
//XXX ==> image			
			landscapePhoto: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'menu',
//TODO share locate stuff. cf pointing directly to image...
				locate: (doc:MenuDoc,locationParams:{course:string,index:number}) => 
					Assert.have(doc.courses[locationParams.course])[locationParams.index],
				field: 'image',
				assetFolder: 'MenuItem-image',
				sizes: imageSize.landscapeThreeTwoAllSizes, 
				previewSize: 'medium'
			},
			squarePhoto: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'menu',
				locate: (doc:MenuDoc,params:{course:string,index:number}) => Assert.have(doc.courses[params.course])[params.index],
				field: 'image',
				assetFolder: 'MenuItem-image',
				sizes: imageSize.squareAllSizes, 
				previewSize: 'medium'
			},
			portraitPhoto: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'menu',
				locate: (doc:MenuDoc,locationParams:{course:string,index:number}) => 
					Assert.have(doc.courses[locationParams.course])[locationParams.index],
				field: 'image',
				assetFolder: 'MenuItem-image',
				sizes: imageSize.a4Portrait,
				previewSize: 'medium'
			},
/*
			shareImage: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'event',
				field: 'shareImage',
				assetFolder: 'Event-shareImage',
				sizes: imageSize.squareAllSizes,
				previewSize: 'medium'
			},
*/			
/*
			poster: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'event',
				field: 'poster',
				assetFolder: 'Event-poster',
				sizes: imageSize.posterSizes,
				previewSize: 'medium'
			},
*/			

//FIXME delete this one... and anything else Im not using...
/*
			builtPoster: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'event',
				field: 'builtPoster',
				assetFolder: 'Event-builtPoster',
				sizes: imageSize.squareAllSizes,
				previewSize: 'medium'
			},
*/			

/*
			suppliedPoster: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'event',
				field: 'image',
				assetFolder: 'Event-image',
				sizes: imageSize.posterSizes,
				previewSize: 'medium'
			},
*/			
		});	
	}

	beforeDisplay(data:MenuItemData)
	{
		super.beforeDisplay(data);

		const funcs = new PageFunctions();
		const urls = new VenueUrls(this.build,this.venue.key);
		const image = data.menu.courses[data.params.course][data.params.index0].image;

		if (image!=undefined) {
//XXX arbitrarily choosing landscapePhoto here. Probably work OK		
			funcs.imageUploadUrl(urls,data.menu._id,MenuItem.pageName,'landscapePhoto',image,'MenuItem-image','menu',{course:data.params.course,index:data.params.index0},'medium','defaults/profile4x5.png');
			image.url = funcs.createImageUrl(urls,image,'MenuItem-image','medium');
		}
	}


/*
	private updateLocatorBar(d:MenuItemData)
	{
		let url;
		const slug = d.params.slug;

		if (d.outerTabs?.selected == 'details') {
			if (d.previewTabs?.selected == 'webpage')
				url = `/admin/event/${slug}/details/webpage`;
			else
				url = `/admin/event/${slug}/details/poster`;
		}
		else if (d.outerTabs?.selected == 'marketing') 
			url = `/admin/event/${slug}/marketing`;
		else 
			Assert.check(false);  //XXX or redirect...

		(new PageFunctions()).updateLocationWithTabs(this.inBrowser,url);
	}
*/	
}
