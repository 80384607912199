import {VenueRoutes as SharedRoutes, VenueRoutesType} from 'Shared/routes/VenueRoutes';
import {Home as BackendHome} from 'Shared/view/backend/DifferentDrummerHome'


export class DifferentDrummerRoutes extends SharedRoutes
{
	frontend()
	{

		return <VenueRoutesType> ({
			...super.frontend(),

//			'/':			[frontendHomePage(this.settings.sharedPages.home), () => ({})],		
		});
	}

	backend()
	{
		return <VenueRoutesType>({
			...super.backend(),

			'/admin/home': 			[BackendHome, ()=>({})],
			'/admin/home/:tab': 	[BackendHome, params=>({tab:params.tab})],
		});
	}
}

