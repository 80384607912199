import {Component} from 'Common/components/Component';
import {ConfigPageError} from 'Common/config/ConfigPageError';
import {SiteBasics} from 'Common/SiteBasics';
import {IPage} from 'Common/pages/IPage';
import {VenueView} from 'Shared/view/VenueView';
import { Breadcrumbs as BreadcrumbsConfig } from 'Common/config/PageConfigTypes';
import {IPageData} from 'Common/PageConfig';

export interface LinkParent {
	page:string,
	component:string
}

type BreadcrumbsData = {
	urls:string[]
};


export class Breadcrumbs extends Component
{
	constructor(instanceName:string,
		private site:SiteBasics,
		private page:IPage<IPageData>,
		private def: BreadcrumbsConfig<IPageData>,
		public data:BreadcrumbsData
	) {
		super(instanceName);
	}

	public componentType():string { return 'breadcrumbs'; }

	async load()
	{
		return {urls: this.createPath()};
	}

//TODO probably easier to combine the NJK breadcrumb bits with the component config bits...

	protected createPath()
	{
		let urls:string[];
		if (this.def.parent == undefined)
			urls = [];
		else {
			/*
				Note the "full" solution would be to create a full page object for every ancestor page,
				but this seems a bit extreme.

				If  the current approach proves lacking consider adding closures to the breadcrumb
				config properties.
			*/

			const configs = (new VenueView()).view();
			const parentPageConfig = configs[this.def.parent.page];
			if (parentPageConfig==undefined)
				throw new ConfigPageError(`Ancestor page '${this.def.parent.page}' doesn't exist`,this.page.name(),this.name);

			const parentObj = new (<any>parentPageConfig)(null,null,null,this.site);

			const parentComponents = parentObj.components();
			const parentDef = <BreadcrumbsConfig<IPageData>|undefined>parentComponents[this.def.parent.component];
			if (parentDef==undefined)
				throw new ConfigPageError(`Ancestor page '${this.def.parent.page}' lacks a '${this.def.parent.component}' component`,this.page.name(),this.name);
			if (parentDef.type!='breadcrumbs')
				throw new ConfigPageError(`Expected component to be of type 'breadcrumbs'`,this.def.parent.page,this.def.parent.component);

			const parentComponent = new Breadcrumbs(this.def.parent.component,this.site,this.page,parentDef,{urls:[]});

			urls = parentComponent.createPath();
		}

		urls.push(this.def.link(this.page.data));
		return urls;
	}
}

