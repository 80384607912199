//{% import 'App/forms/imageUpload.njk'as imageUpload %}

import {ArtistProps} from 'Shared/artists/ProfilePage';
import {IconColour} from 'Shared/backend/fonts/icons/IconColour';
import {LinkOpen} from 'Shared/backend/fonts/icons/LinkOpen.svg';
import {PeopleCircle} from 'Shared/backend/fonts/icons/PeopleCircle.svg';
import {PersonCircle} from 'Shared/backend/fonts/icons/PersonCircle.svg';
import {ImageUpload, Preview, buttonsStyle, uploaderStyle} from 'Shared/forms/ImageUpload';
import {Show, createMemo, onMount} from 'solid-js';
import { css } from '@emotion/css'
import {SolidImageUploadWidget} from 'Browser/widgets/SolidImageUploadWidget';
import {Profile} from 'Common/pages/artist/Profile';
import {usePage} from 'Shared/artists/PageProvider';
import {createSignal} from 'solid-js';
import {ImageUploaderComponent} from 'Common/components/ImageUpoaderComponent';


function uploaderModsStyle()
{
	return  css({
		border: 'none',

		'.bf-imagePreviewImg': {
			maxWidth: 450,
			maxHeight: 300
		}
	});
}


//XXX unused, but an example of the standard uploader
export function PressShotSTANDARD(artist:ArtistProps)
{
	const component = usePage().component('image') as ImageUploaderComponent;

	const widget = () => {
		return new SolidImageUploadWidget(Profile.pageName,component,[],artist._id,true,1.5,800,undefined);
	};

	return <ImageUpload {...artist.image} widget={widget()} fallbackUrl='defaults/profile3x2.png'/>;
}


export function PressShot(artist:ArtistProps)
{
	let uploadNode!:HTMLDivElement;

	const component = usePage().component('image') as ImageUploaderComponent;

	if (artist.inBrowser)
		onMount(() => (new SolidImageUploadWidget(Profile.pageName,component,[],artist._id,true,1.5,800,undefined)).init(uploadNode));

	const [image,setImage] = createSignal(artist.image);
	const imageExists = createMemo(() => image() != undefined);

//TODO PeopleCircle (and PersonCircle) has a 'stroke' but shouldnt
	return (<>
		<h4>Press shot
			<x-notes>VOS will crop image to 3:2 landscape ratio</x-notes>
		</h4>

		<div class={`step ${uploaderStyle()} ${uploaderModsStyle()}`} ref={uploadNode} 
			onLoading={e => setImage({dataUrl:e.detail.dataUrl, uploading:true})}
			onLoaded={e => setImage({...e.detail, uploading:false}) } 
			onDeleted={() => setImage(undefined) }
		>
			<input type='hidden' class='bf-value' value='' /> 

			<div class='stepIcon'>
				{ artist.performanceType=='originalSolo' || artist.performanceType=='coversSolo' || artist.performanceType=='dj' ?
					<PersonCircle fill={imageExists() ? IconColour.active : IconColour.inactive} />
				:
					<PeopleCircle fill={imageExists() ? IconColour.active : IconColour.inactive} />
				}
			</div>

			<div>
				<Show when={artist.facebook || artist.website}>
					<div class='search'>
						<label>
							Search for photos on your 

							<Show when={artist.facebook}>
								<a href={`https://www.facebook.com/${artist.facebook}/photos`} target='_blank' class='active'>
									Facebook page <LinkOpen fill={IconColour.active} />
								</a>
							</Show>

							<Show when={artist.facebook && artist.website}>
								or 
							</Show>

							<Show when={artist.website}>
								<a href={artist.website} target='_blank' class='active'>
									website <LinkOpen fill={IconColour.active} />
								</a>
							</Show>
						</label>
					</div>
				</Show>

				<br/>

				<x-field class={buttonsStyle()}>
					<button type='button' class='bf-imageUploadButton'>
						<i class='fa fa-upload'></i> {imageExists() ? 'Replace' : 'Upload'} image
						<input type='file' accept='.jpg,.jpeg,.png' autocomplete='off' name='files[]' />
					</button>

					<button type='button' class='bf-removeImage' disabled={!imageExists()}>
						<i class='fa fa-times'></i> Remove image
					</button>
				</x-field> 

				<div class={uploaderModsStyle()}>
					<Preview {...image} exists={imageExists()} formats={component.def.sizes}
						assetFolder='PressShot' size='medium' fallbackUrl='defaults/profile3x2.png'
					/> 
				</div>
			</div>
		</div>
	</>);
}

