import {SelectorWidget} from 'Browser/widgets/SelectorWidget';
import {ReadArtistMessage, SearchArtistsMessage, UpdateMessage, CopyArtistImageMessage} from 'Common/Messages';
import 'awesomplete';
import {IPageWrapper} from 'Browser/pages/PageWrapper';
import {Event} from 'Shared/view/backend/Event';
import {locateSubDocument} from 'Shared/view/ViewUtils';
import {genres} from 'Shared/ArtistTypes';
import {ArtistDoc, sharedArtistFields} from 'Shared/model/Artist';

type AwesompletePlus = Awesomplete & {searchResults:any,searchId:any};

export class ArtistSearchWidget extends SelectorWidget
{
    constructor(
		private pageWrapper:IPageWrapper
	)
    {
        super(['.artistSearch']);
//XXX should make app directly available to widgets
        this.updateFields = this.updateFields.bind(this);
	}

    static key():string { return 'artistSearch'; }

	initInstance(anchorNode:HTMLElement):void
    {
        super.initInstance(anchorNode);

		const inputNode = <HTMLInputElement>anchorNode.querySelector('input');
		const buttonNode = <HTMLButtonElement>anchorNode.querySelector('button');

        const awesomplete:AwesompletePlus = <AwesompletePlus> new Awesomplete(inputNode,{
                sort:false,
//                replace: function (suggestion:Awesomplete.Suggestion) {
                replace: function (suggestion:any) {
					if (suggestion.value=='none')
						return;
					(<any>this).input.value = awesomplete.searchResults[suggestion.value].name;
					awesomplete.searchId = suggestion.value;
				},
                filter: (text,input) => true,
//                item: (suggestion:Awesomplete.Suggestion,input) => {
                item: (suggestion:any,input) => {
                    const li = document.createElement('LI');
                    let text:string;
                    if (suggestion.value=='none')
                        text = '<i>No results found</i>';
                    else {
                        const entry = awesomplete.searchResults[suggestion.value];
                        const name = entry.name;
                        const pos = name.toLowerCase().indexOf(input.toLowerCase());
                        text = escapeHtml(name.substring(0,pos))+'<mark>'
                                + escapeHtml(name.substr(pos,input.length))+'</mark>'
                                + escapeHtml(name.substr(pos+input.length));

                        /* add the extra information: */
                        let extra = '';
                        if (entry.city!=null && entry.city!='')
                            extra = escapeHtml(entry.city);
                        if (entry.mainGenre!=null && entry.mainGenre!='') {
                            if (extra!='')
                                extra += ', ';
                            extra += escapeHtml(genres[entry.mainGenre] ?? '');
                        }
                        if (extra!='')
                            text += ' <i>('+extra+')</i>';
                    }

                    li.innerHTML = text;
                    return li;
                }
            });

        /* Store our own copy of the retrieved data in the Awesomplete object for better manipulation: */
        awesomplete.searchResults = null;

        inputNode.addEventListener('input', async e =>
			await this.changedArtistSearchTerm(awesomplete,(<HTMLInputElement>e.target).value)
		);

        inputNode.addEventListener('change', async () =>
			await this.updateFields(await this.pageWrapper.page.server.sendOperation(new ReadArtistMessage(Event.pageName,awesomplete.searchId)))
		);

        buttonNode.addEventListener('click', async e => {
        	e.preventDefault();
			await this.updateFields(await this.pageWrapper.page.server.sendOperation(new ReadArtistMessage(Event.pageName,awesomplete.searchId)));
        });
	}

	beforeUpdate(fromNode:HTMLElement,toNode:HTMLElement):boolean 
    {
		return !this.isAnchorNode(fromNode);
	}

    private async changedArtistSearchTerm(awesomplete:AwesompletePlus,term:string)
    {
        if (term.length<2)
        {
            awesomplete.list = [];
            awesomplete.evaluate();
            return;
        }

		const items = await this.pageWrapper.page.server.sendOperation(
			new SearchArtistsMessage(Event.pageName,term.trim())
		);

		const list = [];
		awesomplete.searchResults = [];
		for (const e of items) {
			awesomplete.searchResults[e._id] = e;
			list.push(e._id);
		}

		if (items.length==0)
			list.push('none');

		awesomplete.list = list;
		awesomplete.evaluate();
    }

	async updateFields(details:ArtistDoc)
	{
//TODO  USE Zod to confirm they are the same??

		const page = this.pageWrapper.page;

		const lineup:any = page.component('lineup'); 
		const lineupIndex = lineup.list.localData.current[0];

		const doc = page.data.event;
		const lineupSubDoc = locateSubDocument(doc,['lineup']);

		/* If an fields are missing then fill them in using undefined: */
		let mergedDetails:Partial<ArtistDoc> = {};
		for (const fieldName of Object.keys(sharedArtistFields))
			mergedDetails[fieldName] = undefined;
		mergedDetails = {...mergedDetails,...details};

		lineupSubDoc[lineupIndex] = mergedDetails;

		/* Copy over the fields with the same name as ours: */

		/* Update the database: */
		const message = new UpdateMessage(page.name(),'editEvent',doc._id,['lineup',lineupIndex],mergedDetails);
		page.server.sendOperationOptimistically(message);

		await this.pageWrapper.refresh();

		(<any>window)?.tinymce?.get(`tinymce-lineup-[${lineupIndex}]-biography`).setContent(mergedDetails.biography ?? '');

		if (mergedDetails.image?.imageExists) {
			const msg = new CopyArtistImageMessage(lineupIndex,doc._id,mergedDetails.image.hash); 
			lineupSubDoc[lineupIndex].image = await page.server.sendOperation(msg);

			await this.pageWrapper.display();
		}
	}
}

function escapeHtml(unsafe:string):string
{
    return unsafe
         .replace(/&/g, "&amp;")
         .replace(/</g, "&lt;")
         .replace(/>/g, "&gt;")
         .replace(/"/g, "&quot;")
         .replace(/'/g, "&#039;");
}
    
