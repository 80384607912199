import {UserPage} from 'Shared/artists/UserPage';
import {ManagerPage} from 'Shared/artists/ManagerPage';
import {SettingsPage} from 'Shared/backend/settingsPage/SettingsPage';
import {ProfilePage} from 'Shared/artists/ProfilePage';
//export * from 'Browser/Hydrate';


export class Templates
{
	public managerPage = ManagerPage;
	public profilePage = ProfilePage;
	public userPage = UserPage;

	public settingPage = SettingsPage;
	constructor() {}
}
