import {SelectorWidget} from 'Browser/widgets/SelectorWidget';
import KeenSlider, {SliderInstance} from 'keen-slider'


/* Integrates the Keen slider into pages.  */
export class SliderWidget extends SelectorWidget
{
    constructor()
    {
        super(['.keen-slider']);
    }

	static key()  { return 'slider'; }

	afterDisplay(anchorNode:HTMLElement)
    {
        super.initInstance(anchorNode);


		let options = anchorNode.dataset.options ? JSON.parse(anchorNode.dataset.options) : {};
		options = {
			loop:		options.loop ?? true, 
			navigation:	options.navigation ?? true,
			auto:		options.auto ?? true,
			sleep:		options.sleep ?? 2000, 
			...options
		};

		const slider = new KeenSlider(anchorNode, options,
			[
				...(options.navigation ? [navigation] : []),
				...(options.auto       ? [(slider:SliderInstance<any,any,any>) => autoSlide(slider,options.sleep)] : []),
			]
		);

		/* Remove event handlers etc when done: */
		const observer = new MutationObserver(mutations => {
			for (let mutation of mutations) 
				if (mutation.removedNodes) 
					for (const node of mutation.removedNodes) 
						if (node == anchorNode) 
							slider.destroy();
		});
		observer.observe(anchorNode.parentNode!,{attributes:false,childList:true,subtree:false});
	}
}


/* ----- Plugins. Copied from the Keen examples. ---- */

function navigation(slider:SliderInstance<any,any,any>) 
{
	let wrapper:HTMLElement, dots:HTMLElement;
	let arrowLeft:HTMLElement, arrowRight:HTMLElement;

	function markup(remove:boolean) 
	{
		wrapperMarkup(remove);
		dotMarkup(remove);
		arrowMarkup(remove);
	}

	function removeElement(e:HTMLElement) 
	{
		e.parentNode!.removeChild(e);
	}

	function createDiv(className:string) 
	{
		const div = document.createElement('div');
		const classNames = className.split(' ');
		classNames.forEach(name => div.classList.add(name));
		return div;
	}

	function arrowMarkup(remove:boolean) 
	{
		if (remove) {
			removeElement(arrowLeft);
			removeElement(arrowRight);
			return;
		}
		arrowLeft = createDiv('arrow arrow--left');
		arrowLeft.addEventListener('click', () => slider.prev());
		arrowRight = createDiv('arrow arrow--right');
		arrowRight.addEventListener('click', () => slider.next());

		wrapper.appendChild(arrowLeft);
		wrapper.appendChild(arrowRight);
	}

	function wrapperMarkup(remove:boolean) 
	{
		if (remove) {
			const parent = wrapper.parentNode;
			while (wrapper.firstChild)
				parent!.insertBefore(wrapper.firstChild, wrapper);
			removeElement(wrapper);
			return;
		}
		wrapper = createDiv('navigation-wrapper');
		slider.container.parentNode.appendChild(wrapper);
		wrapper.appendChild(slider.container);
	}

	function dotMarkup(remove:boolean) 
	{
		if (remove) {
			removeElement(dots);
			return;
		}
		dots = createDiv('dots');
		slider.track.details.slides.forEach((_e:any,idx:any) => {
			var dot = createDiv('dot');
			dot.addEventListener('click', () => slider.moveToIdx(idx));
			dots.appendChild(dot);
		})
		wrapper.appendChild(dots);
	}

	function updateClasses() 
	{
		let slide = slider.track.details.rel;
		slide === 0
			? arrowLeft.classList.add('arrow--disabled')
			: arrowLeft.classList.remove('arrow--disabled');
		slide === slider.track.details.slides.length - 1
			? arrowRight.classList.add('arrow--disabled')
			: arrowRight.classList.remove('arrow--disabled');
		Array.from(dots.children).forEach((dot, idx) => {
			idx === slide
				? dot.classList.add('dot--active')
				: dot.classList.remove('dot--active');
		});
	}

	slider.on('created', () => {
		markup(false);
		updateClasses();
	});

	slider.on('optionsChanged', () => {
		markup(true);
		markup(false);
		updateClasses();
	});

	slider.on('slideChanged', updateClasses);

	slider.on('destroyed', () => markup(true));
}


function autoSlide(slider:SliderInstance<any,any,any>,sleep:number)
{
	let timeout:NodeJS.Timeout;
	let mouseOver = false;

	function clearNextTimeout() 
	{
		clearTimeout(timeout);
	}

	function nextTimeout() 
	{
		clearTimeout(timeout);
		if (mouseOver) return;
		timeout = setTimeout(() => {
			slider.next();
		}, sleep);
	}

	slider.on('created', () => {
		slider.container.addEventListener('mouseover', () => {
			mouseOver = true;
			clearNextTimeout();
		});
		slider.container.addEventListener('mouseout', () => {
			mouseOver = false;
			nextTimeout();
		});
		nextTimeout();
	})

	slider.on('dragStarted', clearNextTimeout);
	slider.on('animationEnded', nextTimeout);
	slider.on('updated', nextTimeout);
}

