import {Component} from 'Common/components/Component';
import {EditableArray} from 'Common/components/EditableArray';
import {NjkEditComponent} from 'Common/components/NjkEditComponent';
import {IConnectionToServer} from 'Common/IConnectionToServer';
import { Document,IPageData} from 'Common/PageConfig';
import {IPage} from 'Common/pages/IPage';
import { EditArray, NjkEditComponent as EditComponentDef, Location, RepeaterComponent as RepeaterComponentDef } from 'Common/config/PageConfigTypes';

/*
	This is really just a convenieance wrapper around an EditComponent and an EditableArray, and probably should remain that way.
	EditComponent is powerful - e.g. it can be used to edit items up and down a whole tree (eg as in the settings page) - you don't 
	need multiple instances of it.  

	EditableArray is mostly the other stuff required for repeaters (e.g. reordering) and could (should?) even be broken into parts.

	Note that backend/Home is currently using "locateUsingFields(doc,['showcase','items'],location)" which assumes that data structures follow
	an object, array, object, array pattern. Its not built into RepeaterComponent though. Not sure how desirable this is... note that is *may* be
	desirable for supporting the dynamically made repeaters-in-repeaters situation.
*/
export class RepeaterComponent<PD extends IPageData,Doc extends Document> extends Component
{
	private list: EditableArray<PD,Doc>;
	private form: NjkEditComponent<PD,Doc>;

	constructor(name:string,
		page:IPage<PD>,
		server:IConnectionToServer,
		doc:Doc,
		def: RepeaterComponentDef<PD,Doc>,
		localData:{current:Location}   
	)
	{
		super(name);

		/* 
			The operations use the repeater config, so only including the fields required by 
			EditArray.ts and EditComponent.ts here.
		*/

		const arrayDef = <EditArray<PD,Doc>> {
			selector: def.selector,
			fieldName: def.fieldName,
			locateListParent: def.locateListParent,
			initialValues: def.initialValues,
		};

		const editDef = <EditComponentDef<PD,Doc>>{
			locate: def.locate,
			processInput: def.processInput,
		};

		this.list = new EditableArray(server,page,name,arrayDef,doc,doc._id,localData);
		this.form = new NjkEditComponent(name,page,server,doc,editDef);

		this.updateField = this.updateField.bind(this);
		this.deleteItem = this.deleteItem.bind(this);
		this.addItemAndOpen = this.addItemAndOpen.bind(this);
		this.toggleItem = this.toggleItem.bind(this);
	}

	componentType() { return 'repeater'; }


	/* Event handler wrappers: */

	updateField(location:Location,fieldName:string)	{ this.form.updateField(location,fieldName); }

	async load()									{ return await this.list.load(); }

    toggleItem(location:Location)					{ this.list.toggleItem(location); }

	async deleteItem(location:Location,index:number)	{ await this.list.deleteItem(location,index); }

	async addItem(location:Location,wait:boolean)	{ await this.list.addItem(location,wait); }

	async addItemAndOpen(location:Location)			{ await this.list.addItemAndOpen(location); }

    postDisplay()												{ this.list.postDisplay(); }
}

