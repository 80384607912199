import { z } from 'zod';
import {Components,DocumentQueries,ImageUploaderComponent, Location} from 'Common/config/PageConfigTypes';
import {ImageUploadWidget} from 'Browser/widgets/ImageUploadWidget';
import * as imageSize from 'Shared/model/imageSize';
import {Menu as MenuDoc,menu as menuModel} from 'Shared/model/Menu';
import {createImageUrls} from 'Shared/view/ViewUtils';
import {Menu} from 'Shared/view/backend/Menu';
import {IPageWrapper} from 'Browser/pages/PageWrapper';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {BackendPageConfig, backendData, backendParams} from 'Common/BackendPageConfig';
import {VenueUrls} from 'Common/VenueUrls';
import Assert from 'Common/Assert';


export const courseParams = backendParams.extend({ 
	course: z.string().optional()
}).strict();	
export type CourseParams = z.infer<typeof courseParams>;

export const courseData = backendData.extend({
	params: courseParams,
	menu: menuModel,

	/* Component data: */
	courseList: z.object({
		current: z.number().optional()
	})
}).strict();
export type CourseData = z.infer<typeof courseData>;


export class Course extends BackendPageConfig<CourseData,CourseParams>
{
	static readonly pageName = 'backend/course';
	name() { return Course.pageName; }

	access() { return 'venueFullEditor' as AccessAreaName; }

	widgets(pageWrapper:IPageWrapper)
	{
		return {
			[ImageUploadWidget.key()]: new ImageUploadWidget(pageWrapper),
//			[SortableWidget.key()]: new SortableWidget(page,page.server)
		};
	}

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			htmlClasses: `${this.htmlClasses} course`,
			template: 'App/backend/menu/coursePage.njk'
		};
	}

	documents(params:CourseParams): DocumentQueries 
	{
		return ({
			...super.documents(params),

//XXX probably best to lot the WHOLE menu and look at caching
			/* NOTE: If putting together a bill, should copy menu name and price - not just link to MenuItem as items can change. */
			menu: {
				type:'object',
				collection:'menu'
			}
		});
	}

	components():Components<CourseData>
	{
		return ({
			breadcrumbs: { 
				type: 'breadcrumbs',
				parent: {page:Menu.pageName,component:'breadcrumbs'},
				link: (data:CourseData) => '/admin/menu/'+data.params.course 
			},
			courseList: {
				type:'editArray', 
				selector: '.gridTable',
				collection: 'menu',
				locateListParent: (doc:MenuDoc) => doc.courses,
				fieldName: (location:Location) => Assert.toString(location[0]),
				redirect: (location:Location,data:CourseData) => '/admin/menu/'+data.params.course+'/'+data.menu.courses[<any>data.params.course].length,
				initialValues: () => ({
					status:'draft',
					tags: []
				}),
			},

//XXX where are we using this? Its a hire thing...
			photo: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'hire',
				field: 'photo',
				assetFolder: 'MenuItem-photo',
				sizes: imageSize.profile3x2,
				previewSize: 'medium'
			},
		});
	}

	beforeDisplay(data:CourseData) 
	{
		super.beforeDisplay(data);

		const urls = new VenueUrls(this.build,this.venue.key);

		for (const course of Object.values(data.menu.courses))
			createImageUrls(urls,course,'image','MenuItem-image','tiny');

/*
		const funcs = new PageFunctions();
		let i=0;
		for (const space of data.hire.space ?? []) {
			if (space?.photo!=undefined) 
				funcs.imageUploadUrl(urls,data.hire._id,Hire.pageName,'photo',space.photo,'Space-photo','hire','space.'+i,'medium','defaults/profile3x2.png');
			i++;
		}
*/		
	}
}

