import {Location} from 'Common/config/PageConfigTypes';
import {createSignal} from 'solid-js';
import {Show,For} from 'solid-js';


export interface IRepeaterProps<ItemData,PageData> {
	component: string,
	location: Location,
	parentSelections: number[],
	items: ItemData[],
	context: PageData,
	renderTitle: (current:ItemData,context:PageData) => string,
	renderAddLabel: (current:ItemData,context:PageData) => string,
//	children: (props:{current:ItemData,parentSelections:number[],context:PageData}) => string,
}

/*
	parentSelections: the 1st items is the one used here. It is trimmed for children.

	TODO have an option to disable form toggling so an overlay can be used with this code instead (eg in lineups)
	     OR BETTER: create an OverlayRepeater
*/


//{% macro repeater(fileName,component,parentLocation,items,parentSelections,addLabel,titleTemplate=false,options={}) %} 

export function Repeater<ItemData,PageData>(props:IRepeaterProps<ItemData,PageData>)
{
//	const [openItem,setOpenItem] = createSignal(props.parentSelections[0]);
	const [openItem,setOpenItem] = createSignal<number|undefined>(undefined);

	return (
		<repeater-pane id={props.component}>
			<repeater-items> 
				<For each={props.items}>
					{(item,index) => { 
						const toggleListener = () => setOpenItem(openItem()==undefined ? index() : undefined);

						return ( 
							<repeater-item data-location={[...props.location,index()]} 
									class={'sortableItem '+(item==openItem() ? 'expanded' : 'collapsed')}>

								<repeater-bar onClick={toggleListener}>
									<i class="fas fa-ellipsis-v"></i> 

									<repeater-title>
										{ props.renderTitle(item,props.context) }
									</repeater-title>
									<repeater-buttons>
										<button-group>
											{index()==openItem() ?
												<button class='collapseButton' onClick={toggleListener}><i class='fas fa-compress' title='Collapse'></i></button>
											:
												<button class='expandButton' onClick={toggleListener}><i class='fas fa-expand' title='Expand'></i></button>
											}
										</button-group>
										<button class='deleteButton' onClick={e => window.runOnComponent2(e,props.component,'confirmAndDeleteItem',JSON.stringify(props.location),index())}>
											<i class='fas fa-trash' title='Delete'></i>
										</button>
									</repeater-buttons>
								</repeater-bar>

								<Show when={index()==openItem()}>
									<repeater-item-content class={`${props.component}-edit`}>

										{props.children({
											...item, 
											parentSelections: props.parentSelections.slice(1),
											context: props.context
										})} 

									</repeater-item-content>
								</Show>
							</repeater-item>
						);
					} }
				</For>
			</repeater-items>

			<button onClick={e => window.runOnComponent(e,props.component,'addItemAndOpen',JSON.stringify(props.location))}>
				{/* XXX maybe put the icon into the caller or disable with an option */}
				<i class="fas fa-plus"></i> {props.renderAddLabel(props.items[props.parentSelections[0]],props.context)}
			</button>
		</repeater-pane>
	);
}


