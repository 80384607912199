import {DocumentQueries, ImageUploaderComponent} from 'Common/config/PageConfigTypes'
import { z } from 'zod';
import {MusicEvent, eventDoc as eventModel,EventDoc as EventDoc} from 'Shared/model/Event';
import {PageFunctions} from 'Browser/PageFunctions';
import * as imageSize from 'Shared/model/imageSize';
import {StickySubNavWidget} from 'Browser/widgets/StickySubNavWidget';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {FrontendPageConfig, frontendData} from 'Common/FrontendPageConfig';
import {VenueUrls} from 'Common/VenueUrls';
import {Title as EventTitle} from 'Shared/frontend/eventPage/StandardTitle';
import {HeadInsert as EventHeadInsert} from 'Shared/frontend/eventPage/StandardHeadInsert';

export const eventParams = z.object({
	slug: z.string()
}).strict();	
export type EventParams = z.infer<typeof eventParams>;

export const eventData = frontendData.extend({
	event: eventModel
}).strict();
export type EventData = z.infer<typeof eventData>;


export class Event extends FrontendPageConfig<EventData,EventParams>
{
	static readonly pageName = 'frontend/event';
	name() { return Event.pageName; }

	access() { return 'venueFrontend' as AccessAreaName; }

	titleTemplate() { return 'App/frontend/eventPage/basicTitle.njk'; }

	headerTemplate() { return 'App/frontend/eventPage/basicHeader.njk'; }

	widgets()
	{
		return {
			[StickySubNavWidget.key()]: new StickySubNavWidget()
		};
	}

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			htmlClasses: `${this.venue.document.settings.frontend.venueClass} frontend eventPage`,
			titleTemplate: EventTitle,
			headTemplate: EventHeadInsert,
			template: 'App/frontend/eventPage/standard.njk'
		};
	}

	documents(params:EventParams): DocumentQueries
	{
		return ({
			...super.documents(params),
			event: {
				type:'object',
				collection:'event',
				aggregate: () => [
					{$match: {slug:params.slug}}
				]
			}
		});
	}

	beforeDisplay(data:EventData)
	{
		super.beforeDisplay(data);

		const funcs = new PageFunctions();
		const urls = new VenueUrls(this.build,this.venue.key);

		if (typeof data.event.image != 'undefined')
			data.event.image.url = funcs.createImageUrl(urls,data.event.image,'Event-image','medium');

		/* Required for page meta data - ie FB and Google */
		if (typeof data.event.poster != 'undefined')
			data.event.poster.url = funcs.createImageUrl(urls,data.event.poster,'Event-poster','medium');

		for (const act of (<MusicEvent>data.event).lineup ?? [])
			if (act.image != undefined)
				act.image.url = funcs.createImageUrl(urls,act.image,'Lineup-image','medium');
	}
}

