import { z } from 'zod';
import * as t from 'Shared/model/basic';


export const special = z.object({
	_id: t.id,
	siteId: t.id,
	name: z.string(),	
	tagline: z.string(), 
	moreInfo: z.string(),
	description: z.string(),
	conditions: z.string(),
	priceOptions: z.string(),
	customPrice: z.string(),
	posterSource: z.string(),

	times: t.eventCalenderSettings,

	imageType: z.string(), 
	imageDimensions: z.string(), 
	image: t.image,

//		['imageTypeDetails',{type:['string',16],default:'useCarouselImage'}],
//		['imageDimensionsDetails',{type:['string',16],default:'portrait'}],
//		['video',{type:['string',300],default:''}],
	imageTypeDetails: z.string(),
	imageDimensionsDetails: z.string(),
	video: z.string(),
})
.partial().required({
	_id: true,
	siteId: true,   //FIXME only in server I think
});


export type Special = z.infer<typeof special>;

