import {BuildBasics} from 'Common/BuildBasics';
import {Id} from 'Common/Id';
import {Location} from 'Common/config/PageConfigTypes';

export interface FullLink
{
	url:string;
	id?:string;
}

export interface PartialLink
{
	partial:string;
	id?:string;
}

export interface ILinks
{
	js: FullLink[];
	css: FullLink[];
	prefixes: {resources?:string};
}


export abstract class Urls
{
	constructor(
		protected build:BuildBasics
	) { }

	abstract imageUrl(assetFolder:string,hash:string,size:string,fileType:string,useProduction:boolean): string;

	uploadImageUrl(page:string,component:string,docId:Id,location:Location):string
	{
		return '/admin/imageUpload/'+page+'/'+component+'/'+docId.toString() + (location==undefined ? '' : '/'+encodeURIComponent(JSON.stringify(location)));
	}

	resourceUrl(relativeUrl:string):string
	{
		return this.buildUrl(`resources/${relativeUrl}`);
	}

	buildUrl(relativeUrl:string):string
	{
		return `/_build/`+this.build.key+'/'+relativeUrl;
	}
}

