import {Components, DocumentQueries} from 'Common/config/PageConfigTypes';
import { z } from 'zod';
import { artistDoc as artistSchema } from 'Shared/model/Artist';
import * as t from 'Shared/model/basic';
import {ArtistPageConfig, artistData, artistParams} from 'Common/pages/artist/ArtistPageConfig';


export const managerParams = artistParams.extend({
}).strict();	
export type ManagerParams = z.infer<typeof managerParams>;


export const managerData = artistData.extend({
	artists: z.array(artistSchema),

	/* Component data types: */
	createArtist: z.object({
		id: t.id,
		name: z.string()
	})
}).strict();
export type ManagerData = z.infer<typeof managerData>;



//XXX warning - manager here refers to anyone who manages an artist. When you log into to profile system
//              you get this page.

export class Manager extends ArtistPageConfig<ManagerData,ManagerParams>
{
	static readonly pageName = 'artist/manager';
	name() { return Manager.pageName; }

	settings()
	{
		return {
			...super.settings(),
			template: this.templates.managerPage
		};
	}

	documents(params:ManagerParams): DocumentQueries
	{
		return ({
//			...bannerQuery(),
			artists: {
				type:'array',
				collection: 'artist'
			}
		});
	}

	components()
	{
		return <Components<ManagerData>>({
			createArtist: {
				type: 'create',
				collection: 'artist',
				initialValues: (data:ManagerData) => {
//					data.createArtist = { ...data.createArtist,name:'New artist' };
					return {name: 'New artist'};
//					data.createEvent = { slug: key };
//					return {slug:key,status:'draft',imageType:'auto',times:{frequency:'once'}};
				},
//				redirect: (data:ManagerData) => `/artists/${data.createArtist.slug}`
				redirect: (data:ManagerData) => `/profile/${data.createArtist.id}`
			}
		});
	}
}

