import { DateTime } from 'luxon';
import NunjucksEnv from 'nunjucksBB';
import {escape as htmlEscape} from 'html-escaper';

export default class NunjucksExtensions
{
	public static extend(env:NunjucksEnv)
	{
		env.addFilter('date',async (value:any,format:any) => {
			if (value==undefined)		//XXX hacky? - but I dont want lots of server error messages
				return null;

			try {
				return DateTime.fromISO(value).toFormat(format);
			}
			catch(err) {
				log.error(err);
				return null;
				//return ''+err;
			}
		});

//TODO probably a good idea to either:
// 1. check all inputs are valid and/or
// 2. catch all exceptions
// as the Nunjucks errors that are returned when problems occur in the extensions are currently very poor

		env.addFilter('ran',async (max:number) => {
			return Math.floor(Math.random() * max)
		});
		/* Note the built-in default() only checks for undefined, not null */
		env.addFilter('def',(value:any,defaultValue:any) => {
			return value!=null ? value : defaultValue;
		});

		/* If not argument is provided show everything */
		env.addGlobal('jsdump',function(this:any,data:any) {
			const content = typeof data == 'undefined' ? this.ctx : data;
			const out ='<pre style="display:none;" class=bf-dump>'+htmlEscape(JSON.stringify(replaceCircular(content,null)))+'</pre>';
			return env.getFilter('safe')(out);
		});

		env.addFilter('spaceless', (value:string) => {
			const out = value
				.replace(/^\s+/, '')
				.replace(/>\s+</g, '><')
				.replace(/\s+$/, '');
			return env.getFilter('safe')(out);
		});
		env.addFilter('setAttribute', (obj:any,key:string,value:any) => {
			if (typeof obj!='object') return obj;
			obj[key] = value;
			return obj;
		});

		env.addFilter('append', (obj:any,value:any) => {
			if (!Array.isArray(obj)) return obj;
			return [...obj,value];
		});

		env.addFilter('removeFirst', (obj:any) => {
			if (!Array.isArray(obj)) return obj;
			return obj.slice(1)
		});
		return env;
	}
}

/* Removes troublesome circular references from objects before dumps */
function replaceCircular(val:any, cache:any):any
{
    cache = cache || new WeakSet();

    if (val && typeof(val) == 'object') {
        if (cache.has(val)) return '[Circular]';

        cache.add(val);

        var obj:any = (Array.isArray(val) ? [] : {});
        for(var idx in val) 
            obj[idx] = replaceCircular(val[idx], cache);

        cache.delete(val);
        return obj;
    }
    return val;
}

