import Sortable from 'sortablejs';
import {ReorderDocumentsMessage} from 'Common/Messages';
import Assert from 'Common/Assert';
import {Id} from 'Common/Id';
import {Component} from 'Common/components/Component';
import {EditDocumentList} from 'Common/config/PageConfigTypes';
import {IPage} from 'Common/pages/IPage';
import {IPageData} from 'Common/PageConfig';


//TODO probably add a 'delete' function in time and possibly a 'create'.
//     At the moment just use for reordering documents.

export class EditableDocumentList extends Component
{
//FIXME either add as parameter to widget spec, or add .sortable into NJK
	private sortableItemSelector = '.sortableItem'; 

    constructor(
		private page:IPage<IPageData>,
		instanceName:string,
		readonly config: EditDocumentList
	)
    {
        super(instanceName);
	}

    componentType() { return 'editDocumentList'; }

	async load()
	{
		return {};
	}

    postDisplay()
    {
		for (const el of document.querySelectorAll(this.config.selector)) {
			/* Due to tabs etc the component may not present */
			if (el==undefined)
				return;
			const anchorNode = Assert.htmlElement(el);

			const sortable = new Sortable(anchorNode, {
				handle: this.sortableItemSelector,
				onEnd: async () => {
					const op = this.createShuffleOperation(anchorNode);
					await this.page.server.sendOperation(op);
				}
			});
		}
    }

	private createShuffleOperation(anchorNode:HTMLElement)
	{
//FIXME currently wont work with pagination as positions are reassigned... note that assigning positions to anything
//      with more than one page is problematic anyway, but will probably need to support eventually.

		const items:NodeListOf<HTMLElement> = anchorNode.querySelectorAll(this.sortableItemSelector);

		/* Create rows for message, creating nice new positions: */
		const rows:Id[] = [];
		for (let i=0; i<items.length; i++) {
			const item = items[i].dataset;
			rows.push(Assert.have(item.id));
		}

		/* 
			The page and component names are only used to look up the 'access' server side.
			Providing the table name from the browser instead of in the json can potentially allow nested sorting.
		 */
		return new ReorderDocumentsMessage(this.page.name(),this.name,<any>rows);
	}
}

