import {ILinks} from 'Common/config/ILinks';

//FIXME replace hard-coded page names...

export const Links:ILinks = {
	/*
		The key is the link name, and for pages should be the same as the page name.
	 */
	'frontend/home':			{type:'page',page:'frontend/home',			target:'/'},
	'frontend/events':			{type:'page',page:'frontend/events',		target:'/events'},
	'frontend/event':			{type:'page',page:'frontend/event',			target:'/event/:slug'},
	'frontend/event/eventArtistProfile':
								{type:'page',page:'frontend/eventArtistProfile',target:'/event/:id/artistProfile'},
	'frontend/menu':			{type:'page',page:'frontend/menu',			target:'/menu'},
	'frontend/food':			{type:'page',page:'frontend/food',			target:'/food'},
	'frontend/drinks':			{type:'page',page:'frontend/drinks',			target:'/drinks'},
	'frontend/menuPoster':		{type:'page',page:'frontend/menuPoster',	target:'/menu-poster/:id'},
	'frontend/specialPoster':	{type:'page',page:'frontend/specialPoster',	target:'/special-poster/:id'},
	'frontend/hire':			{type:'page',page:'frontend/hire',			target:'/hire'},
	'frontend/contact':			{type:'page',page:'frontend/contact',		target:'/contact'},
	'frontend/members':			{type:'page',page:'frontend/members',		target:'/members'},
	'frontend/reservation':		{type:'page',page:'frontend/reservation',	target:'/reservation'},
	'frontend/pageNotFound':	{type:'page',page:'frontend/pageNotFound',	target:'/pageNotFound'},

	'backend/venue':	{type:'page', page:'backend/venue',		target:'/admin'},
	'backend/home':		{type:'page', page:'backend/home',		target:'/admin/home'},
	'backend/contact':	{type:'page', page:'backend/contact',	target:'/admin/contact'},
	'backend/hire':		{type:'page', page:'backend/hire',		target:'/admin/hire'},
	'backend/events':	{type:'page', page:'backend/events',	target:'/admin/events'},
	'backend/hr':		{type:'page', page:'backend/hr',		target:'/admin/hr'},
	'backend/event':	{type:'page', page:'backend/event',		target:'/admin/event/:slug'},
	
	// discontinued 'backend/event/v2':	{type:'page', page:'backend/event/v2',		target:'/admin/event/v2/:id'},
	'backend/menu':		{type:'page', page:'backend/menu',		target:'/admin/menu'},
	'backend/course':	{type:'page', page:'backend/course',	target:'/admin/menu/:course'},
	'backend/menuItem':	{type:'page', page:'backend/menuItem',	target:'/admin/menuItem/:id'},
	'backend/brands':	{type:'page', page:'backend/brands',	target:'/admin/brands'},
	'backend/brand':	{type:'page', page:'backend/brand',		target:'/admin/brand/:id'},
	'backend/specials':	{type:'page', page:'backend/specials',	target:'/admin/specials'},
	'backend/special':	{type:'page', page:'backend/special',	target:'/admin/special/:id'},
	'backend/settings':	{type:'page', page:'backend/settings',	target:'/admin/settings'},

	'backend/dummy1s':	{type:'page', page:'backend/dummy1s',	target:'/admin/dummy1s'},
	'backend/dummy1':	{type:'page', page:'backend/dummy1',	target:'/admin/dummy1/:id'},
	
	'backend/staffResources':	{type:'page', page:'backend/staffResources',	target:'/admin/staffResources'},
	'backend/staffResource':	{type:'page', page:'backend/staffResource',	target:'/admin/staffResource/:id'},

	'frontend/staffResources':	{type:'page', page:'frontend/staffResources',	target:'/staffResources'},
	'frontend/staffResource':	{type:'page', page:'frontend/staffResource',	target:'/staffResource/:id'},

	'backend/people':	{type:'page', page:'backend/people',	target:'/admin/people'},
	'backend/person':	{type:'page', page:'backend/person',	target:'/admin/person/:id'},
	
	//CJ discontinue if 'people' does the job
	'backend/staffHub':	{type:'page', page:'backend/staffHub',	target:'/admin/staffHub'},
	
	
	
	/* 
		Example external link:
			'google':         {type:'external', target:'https://google.com'},
	 */
}; 

