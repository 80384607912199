import {IPageWrapper} from 'Browser/pages/PageWrapper';
import {JsPageWidget} from 'Browser/widgets/JsPageWidget';
import {Components, DocumentQueries} from 'Common/config/PageConfigTypes';
import {Id} from 'Common/Id';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {ILinks} from 'Common/Urls';
import {Templates} from 'Shared/Templates';
import {PageSettingsType} from 'Shared/view/VenueSettingsType';

export type Document = { _id:Id } & {[key:string]:any};

export interface IPageParams
{
	[name:string]: any;
}



export interface IPageData extends PageSettingsType
{
	params: IPageParams;
//	link: any;		//FIXME any
	[name:string]: any;
}

export abstract class PageConfig<PageData extends IPageData,PageParams extends IPageParams>
{
	static readonly pageName:string;

	/*
		Venue config pages are constructed in a dynamic fashion, so they all need the same parameters.
		Hence it makes sense for them to share a constructor.
	 */
	constructor(
		readonly inBrowser:boolean,
		protected templates:Templates
	) { 
	}

	abstract name():string;

	abstract access(): AccessAreaName;

	abstract includes(): ILinks;

	requiresLogin() { return true; }

	widgets(page:IPageWrapper):{[name:string]:JsPageWidget} { return {}; }

	abstract settings():PageSettingsType;

	documents(params:PageParams): DocumentQueries {return {};}

	components(): Components<PageData> { return <any>{}; }

	beforeDisplay(data:PageData) { return <any>{}; }
}

