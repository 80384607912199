import { DocumentQueries} from 'Common/config/PageConfigTypes'
import { z } from 'zod';
import {PageFunctions} from 'Browser/PageFunctions';
import {MusicEvent, eventDoc as eventModel} from 'Shared/model/Event';
import {FutureEventsQueryClause} from 'Shared/view/FutureEventsQueryClause';
import {PastEventsQueryClause} from 'Shared/view/PastEventsQueryClause';
import {IServerFunctions} from 'Server/ServerFunctions';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {FrontendPageConfig, frontendData} from 'Common/FrontendPageConfig';
import {TimesFunctions} from 'Common/TimesFunctions';
import {VenueUrls} from 'Common/VenueUrls';

export const eventsParams = z.object({}).strict();	
export type EventsParams = z.infer<typeof eventsParams>;

export const eventsData = frontendData.extend({
	pageDisplayName: z.string(),
	events: z.array(eventModel),
	pastEvents: z.array(eventModel)
}).strict();
export type EventsData = z.infer<typeof eventsData>;


export class Events extends FrontendPageConfig<EventsData,EventsParams>
{
	static readonly pageName = 'frontend/events';
	name() { return Events.pageName; }

	access() { return 'venueFrontend' as AccessAreaName; }

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			htmlClasses: `${this.htmlClasses} eventsPage`,
			template: 'App/frontend/eventsPage/standard.njk',
			pageDisplayName: 'Events'
		};
	}

	documents(params:EventsParams): DocumentQueries
	{
		const times = new TimesFunctions(this.venue.document.settings.general.timezone,this.venue.document.settings.general.testNow);

		return ({
		//XXX could potentially group these templates into, e.g. template: { body:..., head:..., ...}
			...super.documents(params),

			events: {
				type: 'array',

				collection:'event',
				/*
					We want events that haven't ended yet. We don't care when they started. 
					Note that "timesEnd" sometimes includes an extra day to cope with the "until close" situation.
				 */
				aggregate: () => [
					{$match: {
						$and: [
							{status: 'published'},
							(new FutureEventsQueryClause(times,'times',{months:3})).create()
						],
					}},
					{$limit: 1000}
				],

				transform: (funcs:IServerFunctions,doc:AsyncIterable<any>) => {		//XXX any 
					const eventsPlus = funcs.generateEvents(doc,'times',
						/*start:*/times.now(),
						/*end:*/  times.add(times.today(),{months:3} )
					);

					const filtered = funcs.filterTimes(eventsPlus,'times',{/*onceLimit:0,*/dailyLimit:1,weeklyLimit:1,monthlyLimit:1});
					return funcs.limit(filtered,30)
				}
			},

			pastEvents: {
				type: 'array',

//TODO possibly have a query closure that includes collection. Allows find() to be used etc
				collection:'event',
//TODO resolve on server
				/*
					We want events that haven't ended yet. We don't care when they started. 
					Note that "timesEnd" sometimes includes an extra day to cope with the "until close" situation.
				 */
				aggregate: () => [
					{$match: {
						$and: [
							{status: 'published'},
							(new PastEventsQueryClause(times,'times',{months:3})).create()
						],
					}},
					{$sort: {'times.startSingle':-1,'times.startDate':-1}},
					{$limit: 1000}

					//TODO probably should reverse sort this. Probably easiest to add a reverse sort transform function
				],

/* FIXME probably want this, but orders ascending
				transform: (doc:AsyncIterable<any>) => {		//XXX any 
					Assert.exists(func);
					const eventsPlus = func.generateEvents(doc,'times',
						*start:*time.minus(time.today(),{months:3} ),
						*end:*  time.now()
					);

					const filtered = func.filterTimes(eventsPlus,'times',{*onceLimit:0,*dailyLimit:1,weeklyLimit:1,monthlyLimit:1});
					return func.limit(filtered,30)
				}
*/				
			},
		});
	}

	beforeDisplay(data:EventsData) 
	{
		super.beforeDisplay(data);

		const urls = new VenueUrls(this.build,this.venue.key);
		const funcs = new PageFunctions();

		for (const event of [...data.events, ...data.pastEvents]) {
//			if (event.poster!=undefined)
//				event.poster.url = funcs.createImageUrl(urls,event.poster,'Event-poster','large');

			if (event.image!=undefined)
				if (event.image!=undefined)
					event.image.url = funcs.createImageUrl(urls,event.image,'Event-image','medium');

			for (const act of (<MusicEvent>event)?.lineup ?? [])
				if (act.image!=undefined)
					act.image.url = funcs.createImageUrl(urls,act.image,'Lineup-image','medium');
		}
	}
}

