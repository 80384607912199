import {Components, DocumentQueries, OverlayComponent} from 'Common/config/PageConfigTypes'
import { z } from 'zod';
import {eventDoc as eventModel,musicEvent as musicEventModel} from 'Shared/model/Event';
import {home as homeModel} from 'Shared/model/Home';
import {venueDoc} from 'Shared/model/venue';
import {ParallaxWidget} from 'Browser/widgets/frontend/ParallaxWidget';
import {FadePageInWidget} from 'Browser/widgets/FadePageInWidget';
import {createImageUrl, createImageUrls} from 'Shared/view/ViewUtils';
import { musicEvents,nonMusicEvents } from 'Shared/view/frontend/eventQueries';
import { specialsQueries } from 'Shared/view/frontend/specialsQueries';
import {IPageWrapper} from 'Browser/pages/PageWrapper';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {FrontendPageConfig, frontendData} from 'Common/FrontendPageConfig';
import {TimesFunctions} from 'Common/TimesFunctions';
import {VenueUrls} from 'Common/VenueUrls';
import {ExpandedCollapsedWidget} from 'Browser/widgets/frontend/ExpandedCollapsedWidget';
import {ScrollRangeVariableWidget} from 'Browser/widgets/frontend/ScrollRangeVariableWidget';
import {SliderWidget} from 'Browser/widgets/frontend/SliderWidget';

export const homeParams = z.object({}).strict();	
export type HomeParams = z.infer<typeof homeParams>;

export const homeData = frontendData.extend({
	venue: venueDoc.extend({
		vosLogo: z.string().optional()
	}),
	home: homeModel,
	musicEvents: z.array(musicEventModel),
	nonMusicEvents: z.array(eventModel),
	socialEvents: z.array(eventModel),
//FIXME not an event	
	specials: z.array(eventModel)
}).strict();
export type HomeData = z.infer<typeof homeData>;


export class Home extends FrontendPageConfig<HomeData,HomeParams>
{
	static readonly pageName = 'frontend/home';
	name() { return Home.pageName; }

	access() { return 'venueFrontend' as AccessAreaName; }

	widgets(page:IPageWrapper)
	{
		return {
			[ParallaxWidget.key()]: new ParallaxWidget(['.parallaxWidget']),
			[FadePageInWidget.key()]: new FadePageInWidget(),
			[ScrollRangeVariableWidget.key()]: new ScrollRangeVariableWidget(),
			[ExpandedCollapsedWidget.key()]: new ExpandedCollapsedWidget(),
			[SliderWidget.key()]: new SliderWidget()  
		};
	}

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			htmlClasses: `${this.htmlClasses} homePage`,
			template: 'App/frontend/homePage/standard2.njk'
		};
	}

	documents(): DocumentQueries
	{
		const times = new TimesFunctions(this.venue.document.settings.general.timezone,this.venue.document.settings.general.testNow);

		return ({
//			...footerDocuments(),
			home: {
				type:'object',
				collection:'home'
			},

			musicEvents: musicEvents(times,'times'),

			nonMusicEvents: nonMusicEvents(times,'times'),

			specials: specialsQueries(times,'times')
		});
	}

	components(): Components<HomeData>
	{
		return ({
			reveal: <OverlayComponent>{
				type: 'overlay',
				panes: {
					specialsOverlay: false
				}
			},

			currentRecord: {
				type: 'extraData',
				initial: null
			},

			...super.components()
		});
	}

	beforeDisplay(data:HomeData)
	{
		super.beforeDisplay(data);

		const urls = new VenueUrls(this.build,this.venue.key);

		createImageUrl(urls,data.home.featuredProductImage,'Home-featuredProductImage','large');

		createImageUrls(urls,data.musicEvents,'image','Event-image','medium');
		createImageUrls(urls,data.nonMusicEvents,'image','Event-image','medium');
		for (const event of data.musicEvents)
		createImageUrls(urls,event.lineup,'image','Lineup-image','medium');

		createImageUrls(urls,data.specials,'image','Special-image','medium');

		for (const section of data.home.showcase ?? []) {
			//TODO fix up the bad showcase data and remove the try...catch
//			try{ 
				createImageUrl(urls,section.image,'Showcase-image','medium'); 
//			} catch(err) { 
//				log.warn(err); 
//			}
			if (section.layout=='carousel') 
				createImageUrls(urls,section.items,'image','Home-showcaseCarouselImage','large');
		}
	}
}

