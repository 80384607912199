import {ArtistUrls} from 'Common/ArtistUrls';
import {BuildBasics} from 'Common/BuildBasics';
import { IPageData, PageConfig} from 'Common/PageConfig';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {Templates} from 'Shared/Templates';
import { z } from 'zod';
import {HeadInsert} from 'Shared/artists/HeadInsert';
import {Title} from 'Shared/artists/Title';
import { PageSettingsType } from 'Shared/view/VenueSettingsType';

export const artistParams = z.object({ }).strict();
export type ArtistParams = z.infer<typeof artistParams>;


export const artistData = z.object({
	params: artistParams,
	template: z.string(),
	htmlClasses: z.string()
}).strict();
export type ArtistData = z.infer<typeof artistData>;


//export type ArtistPageConfigClass = new (inBrowser:boolean,templates:Templates,build:BuildBasics) => ArtistPageConfig<ArtistData,ArtistParams>;
export type ArtistPageConfigClass = new (inBrowser:boolean,templates:Templates,build:BuildBasics) => PageConfig<IPageData,ArtistParams>;



export abstract class ArtistPageConfig<PageData extends ArtistData,PageParams extends ArtistParams> 
	extends PageConfig<PageData,PageParams>
{
	constructor(
		inBrowser:boolean,
		protected templates:Templates,
		protected build:BuildBasics
	) 
	{ 
		super(inBrowser,templates);
	}

	access() { return 'artist' as AccessAreaName; }

	includes() { return (new ArtistUrls(this.build)).urls(); }

	settings()
	{
		return <PageSettingsType> {
			htmlClasses: 'artistPage',

			/* Solid is only used in the body */
			titleTemplate: Title,
			headTemplate: HeadInsert,

			template: 'DUMMY'
		}
	}
}

