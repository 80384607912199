import { z } from 'zod';
import {eventDoc as eventModel} from 'Shared/model/Event';
import {Breadcrumbs, CreateComponent,DocumentQueries } from 'Common/config/PageConfigTypes';
import {FutureEventsQueryClause} from 'Shared/view/FutureEventsQueryClause';
import {PastEventsQueryClause} from 'Shared/view/PastEventsQueryClause';
import {PageFunctions} from 'Browser/PageFunctions';
import {Venue} from 'Shared/view/backend/Venue';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {BackendPageConfig, backendData} from 'Common/BackendPageConfig';
import {TimesFunctions} from 'Common/TimesFunctions';
import {VenueUrls} from 'Common/VenueUrls';


export const eventsParams = z.object({ }).strict();	
export type EventsParams = z.infer<typeof eventsParams>;

export const eventsData = backendData.extend({
	events: z.array(eventModel),
	pastEvents: z.array(eventModel),

	/* Component data types: */
	createEvent: z.object({
		slug: z.string()
	})
}).strict();
export type EventsData = z.infer<typeof eventsData>;


export class Events extends BackendPageConfig<EventsData,EventsParams>
{
	static readonly pageName = 'backend/events';
	name() { return Events.pageName; }

	access() { return 'venueEventEditor' as AccessAreaName; }

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			htmlClasses: `${this.htmlClasses} events`,
			template: 'App/backend/eventsPage/eventsPage.njk'
		};
	}

	documents(params:EventsParams): DocumentQueries 
	{
		const times = new TimesFunctions(this.venue.document.settings.general.timezone,this.venue.document.settings.general.testNow);
		//todo ben urgent we need a list of events if someone forgets to set any date, below we're only listing current events and past events, not ones that have no date set 
		return ({
			...super.documents(params),
			events: {
				type: 'array',
				collection: 'event',
				aggregate: () => [
					{'$match': (new FutureEventsQueryClause(times,'times',{years:100})).create() },
					{'$limit': 100},
					{'$sort': {'times.startSingle':1,'times.startDate':1}},
				],
			},
			pastEvents: {
				type: 'array',
				collection: 'event',
				aggregate: () => [
					{'$match': (new PastEventsQueryClause(times,'times',{years:100})).create() },
					{'$sort': {'times.startSingle':-1,'times.startDate':-1}},
					{'$limit': 1000},
				],
			},
		});
	}

	components()
	{
		return ({
//FIXME  Have limit and page stuff in there by default
//XXX banner and breadcrumbs are having issues			

			breadcrumbs: <Breadcrumbs<EventsData>>{
				type: 'breadcrumbs',
				parent: {page:Venue.pageName,component:'breadcrumbs'},
				link: () => '/admin/events' 
			},
			createEvent: <CreateComponent<EventsData>>{
				type: 'create',
				collection: 'event',
				initialValues: (data:EventsData) => {
//TODO move to 'createSlug()' utility somewhere
					const key = Math.floor(((new Date()).getTime()-1697150000000)/1000).toString(36).toUpperCase();
//XXX add a random bit on this too
					data.createEvent = { slug: key };
					return {slug:key,status:'draft',imageType:'auto',times:{frequency:'once'}};
				},
//FIXME place in links.json?
				redirect: (data:EventsData) => `/admin/event/${data.createEvent.slug}`
			}
		});
	}

	beforeDisplay(data:EventsData) 
	{
		super.beforeDisplay(data);

		const funcs = new PageFunctions();
		const urls = new VenueUrls(this.build,this.venue.key);

		for (const event of [...data.events,...data.pastEvents]) 
			if (event.poster!=undefined)
				event.poster.url = funcs.createImageUrl(urls,event.poster,'Event-poster','tiny');
	}
}
