import { z } from 'zod';
import {Components,DocumentQueries} from 'Common/config/PageConfigTypes';
import {menu as menuModel} from 'Shared/model/Menu';
import {Venue} from 'Shared/view/backend/Venue';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {BackendPageConfig, backendData} from 'Common/BackendPageConfig';


export const menuParams = z.object({}).strict();	
export type MenuParams = z.infer<typeof menuParams>;

export const menuData = backendData.extend({ 
	menu: menuModel
}).strict();
export type MenuData = z.infer<typeof menuData>;


export class Menu extends BackendPageConfig<MenuData,MenuParams>
{
	static readonly pageName = 'backend/menu';
	name() { return Menu.pageName; }

	access() { return 'venueFullEditor' as AccessAreaName; }

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			htmlClasses: `${this.htmlClasses} menu`,
			template: 'App/backend/menu/menuPage.njk'
		};
	}

	documents(params:MenuParams): DocumentQueries 
	{
		return ({
			...super.documents(params),
			menu: {
				type:'object',
				collection: 'menu',
				/* Don't project, load everything and cache. TODO cache! */
				aggregate: () => [ ]
			}
		});
	}

	components():Components<MenuData>
	{
		return ({
			breadcrumbs: {
				type: 'breadcrumbs',
				parent: {page:Venue.pageName,component:'breadcrumbs'},
				link: () => '/admin/menu'
			}
		});
	}
}

