import Transport from 'Common/logs/Transport';
import Format from 'Common/logs/Format';
import ILogger from 'Common/logs/ILogger';
	

type TransportInfo = {
	transport:Transport,
	format:Format,
	level:string
};

/*
	NOTE ON CLOSING:
	Currently not closing files. Don't think this matters much.  I think the file handles are freed on process exit.
	I have the server flushing every second, so if you manually break the server soon after something happens you might
	miss something, but that's OK. Uncaught exceptions are being handled and shouldn't crash the server.  
	SLIGHT IMPROVEMENT: I guess we could have an immediate flush up until the time uncaught exception handler is initialised, 
	and then go to once-a-second flushing.
 */
export default class Logger implements ILogger
{
	private transports:TransportInfo[] = [];
	private util;

	constructor(isBrowser:boolean)
	{
		this.util = isBrowser ? null : require('util');
	}

	public async add(transport:Transport,format:Format,level:string):Promise<void>
	{
		this.transports.push({transport:transport,format:format,level:level});
		await transport.open();
	}

//TODO call close()?

	public debug(...args:any[])
	{
		this.output('debug',...args);
	}

	public debugBold(...args:any[])
	{
		this.output('debugBold',...args);
	}

	public info(...args:any[])
	{
		this.output('info',...args);
	}

	public warn(...args:any[])
	{
		this.output('warn',...args);
	}

	public error(...args:any[])
	{
		this.output('error',...args);
	}

	public all(...args:any[])
	{
		this.output('all',...args);
	}

	public trace():string
	{
		return (new Error()).stack ?? '';
	}

	/* Provides a better dump of an object (at least on the server) */
	public inspect(obj:any):string
	{
		return this.util!=null ? this.util.inspect(obj) : JSON.stringify(obj,null,2);
	}


//TODO implement a log.trace('debug')?  Maybe a log.dir('debug',obj) OR log.obj('debug',obj)  OR   log.debug(log.trace())  log.debug(log.obj(obj))
//   XXX maybe always expand out objects fully.

	private output(level:string,...args:any[])
	{
		const l = this.levelValue(level);

		for (const t of this.transports) {
			if (l >= this.levelValue(t.level)) {
				const message = t.format.message(level,...args);

				/*dont wait*/<void><unknown>
				t.transport.output(message);
			}
		}
	}

	private levelValue(level:string):number
	{
		switch(level) {
			case 'debug': return 1;
			case 'debugBold': return 1;
			case 'info': return 2;
			case 'warn': return 3;
			case 'error': return 4;
			case 'all': return 10;
			default:
				throw new Error(`Invalid logging level "${level}"`);
		}
	}
}

