import Logger from 'Common/logs/Logger';
import BrowserTransport from 'Browser/logs/BrowserTransport';
import BrowserFormat from 'Browser/logs/BrowserFormat';
import pageJs from 'page'; 
import * as Run from 'Browser/Run';
import {User} from 'Browser/User';
import {SiteBasics} from 'Common/SiteBasics';
import {PageHandler} from 'Browser/PageHandler';
import { SiteType, selectPageCreator, selectRoutes } from 'Browser/SiteTypeSelector';
import {VenueBasics} from 'Common/VenueBasics';
import {Templates} from 'Shared/Templates';
import {Params} from 'Common/pages/IPage';
import {IRoutes} from 'Common/IRoutesProvider';
import {IPageCreator} from 'Common/IPageCreator';

//registerServiceWorker();  <-- Helps with caching when offline on a slow connection

//XXX perhaps raise the level for production   
window.log = new Logger(true);
log.add(new BrowserTransport(),new BrowserFormat(),'debug');


export class Browser
{
	constructor(
		private router:PageJS.Static
	) { }

	async run()
	{
		window['pageJs'] = pageJs;
		window['nunjucksExtraData'] = {};
		window.run = Run.run as any;
		window.runNoRefresh = Run.runNoRefresh as any;
		window.runOnComponent = Run.runOnComponent;
		window.runOnComponent2 = Run.runOnComponent2;
		window.runOnWidget = Run.runOnWidget;
		window.runOnWidgetNoRefresh = Run.runOnWidgetNoRefresh;
		window.user = User.fromJson(window.user);

		const pageHandler = new PageHandler();

		const site = this.createSiteBasics(window.site);
		const pageCreator = selectPageCreator(new Templates(), window.build,site);

		const routes = selectRoutes(window.build,site);
		addRoutesToPageJs(this.router,pageHandler,routes,pageCreator);
		this.router();
	}

	createSiteBasics(json:any)
	{
//XXX could checkout json with Zod	
		if (json.type==SiteType.venue)
			return new VenueBasics(json.id,json.type,json.key,json.document);

		return new SiteBasics(json.id,json.type,json.key);
	}
}

const browser = new Browser(pageJs);
browser.run().catch(err => log.error(err));



function addRoutesToPageJs(router:PageJS.Static,pageHandler:PageHandler,routes:IRoutes,pageCreator:IPageCreator)
{ 
	/* Add all routes and redirects to the PageJs router */
	for (const [path,details] of Object.entries(routes)) {

//FIXME allow redirects again... see notes in ArtistRoutes
//		if (details.type=='page') {

			const [pageClass,paramMapping] = details;
			const pageResolver = (params:Params) => pageCreator.create(pageClass,paramMapping(params));
			router(path,pageHandler.change(pageResolver));
//		}
//		else if (details.type=='redirect')
//			router(path,details.target);
	}


	/* End of every route: */
	router.exit('*', (ctx:PageJS.Context,next) => {
		ctx.state.scrollX = window.scrollX;
		ctx.state.scrollY = window.scrollY;
		ctx.save();

		/* Note that changes to the hash trigger exit(). Don't force a page update for them, but do update the URL. */
		if (ctx.page.current == ctx.path)
			history.pushState(null, 'FeaturePoints Login', ctx.page.prevContext.canonicalPath);
		else
			next();
	});
}


