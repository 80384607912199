import {SiteBasics as SiteBasics} from 'Common/SiteBasics';
import Assert from 'Common/Assert';
import {ArtistRoutes as ArtistRoutes} from 'Common/artists/ArtistRoutes';
import {BuildBasics} from 'Common/BuildBasics';
import {ConfigFactoryCreator} from 'Shared/config/ConfigFactoryCreator';
import AjaxConnectionToServer from 'Browser/AjaxConnectionToServer';
import {VenuePage} from 'Common/pages/VenuePage';
import {Nunjucks} from 'Browser/Nunjucks';
import {IVenueConfig} from 'Shared/config/IVenueConfig';
import {ArtistPage} from 'Common/pages/ArtistPage';
import {VenueBasics} from 'Common/VenueBasics';
import {VenuePageConfigClass} from 'Shared/routes/VenueRoutes';
import {ArtistPageConfigClass} from 'Common/pages/artist/ArtistPageConfig';
import {Params} from 'Common/pages/IPage';
import {PageStringRenderer} from 'Common/PageStringRenderer';
import {Templates} from 'Shared/Templates';
import {IPageParams, PageConfig} from 'Common/PageConfig';
import {IPageCreator} from 'Common/IPageCreator';
import {IPageConfigCreator} from 'Common/IPageConfigCreator';
import {IRoutes} from 'Common/IRoutesProvider';


/*private*/ class VenuePageConfigCreator implements IPageConfigCreator 
{
	constructor(
		private browserTemplates:Templates,
		private build:BuildBasics,
		private venue:VenueBasics
	) {}

	create(pageClass: VenuePageConfigClass,params:Params) 
	{
		const pageConfig = new pageClass(true,this.browserTemplates,this.build,this.venue);
		const pageStringRenderer = new PageStringRenderer((new Nunjucks()).renderer(),pageConfig);

		return new VenuePage(pageConfig,new AjaxConnectionToServer(),window.build,params,window.user,this.venue,pageStringRenderer);
	}
}

/*private*/ class ArtistPageConfigCreator implements IPageConfigCreator 
{
	constructor(private browserTemplates:Templates,private build:BuildBasics,private site:SiteBasics) {}

	create(pageClass:ArtistPageConfigClass,params:Params) 
	{
		const pageConfig:PageConfig<any,IPageParams> = new pageClass(true,this.browserTemplates,this.build); 
		const pageStringRenderer = new PageStringRenderer((new Nunjucks()).renderer(),pageConfig);

		return new ArtistPage(pageConfig,new AjaxConnectionToServer(),window.build,params,window.user,this.site,pageStringRenderer);
	}
}


export enum SiteType {
	venue = 'venue',
	artist = 'artist',
	company = 'company',
	admin = 'admin',
	/* Shared elements of venue sites. Dummy router, doesn't actually exist: */
	shared = 'shared',
}

export function selectRoutes(build:BuildBasics,site:SiteBasics)
{
	switch(site.type) {
		case SiteType.venue:	
			const config = <IVenueConfig> (new ConfigFactoryCreator()).create(site);
			return <IRoutes> config.routes(false,build,Assert.child(VenueBasics,site));

		case SiteType.artist:
			return <IRoutes> (new ArtistRoutes()).routes();
	}
	Assert.check(false);
}

export function selectPageCreator(browserTemplates:Templates,build:BuildBasics,site:SiteBasics): IPageCreator 
{
	switch(site.type) {

		case SiteType.venue: 
			return new VenuePageConfigCreator(browserTemplates,build,Assert.child(VenueBasics,site));

		case SiteType.artist: 
			return new ArtistPageConfigCreator(browserTemplates,build,site);
	}
	Assert.check(false);
}


export function selectNav(site:SiteBasics)
{
	switch(site.type) {
		case SiteType.venue:
			return (<IVenueConfig>Assert.have((new ConfigFactoryCreator()).create(site))).nav();
	}
	Assert.check(false);
}


export function selectLinks(site:SiteBasics)
{
	switch(site.type) {
		case SiteType.venue:
			return (<IVenueConfig>Assert.have((new ConfigFactoryCreator()).create(site))).link();
	}
	Assert.check(false);
}

