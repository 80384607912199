import {BuildBasics} from 'Common/BuildBasics';
import {PageConfig} from 'Common/PageConfig';
import { z } from 'zod';
import {VenueBasics} from 'Common/VenueBasics';
import {Templates} from 'Shared/Templates';
import {PageSettingsType, VenueAndPageSettingsType, VenueSettingsType, venueSettingsType} from 'Shared/view/VenueSettingsType';

export const venueParams = z.object({}).strict();	
export type VenueParams = z.infer<typeof venueParams>;

//XXX most of this stuff could probably be more easily with interfaces. 
//    The 'documents' want to be Zod, and maybe the params
export const venueData = venueSettingsType.extend({
	params: venueParams,
	venueClass: z.string(),
}).strict();
export type VenueData = z.infer<typeof venueData>;



export abstract class VenuePageConfig<PD extends VenueData,PP extends VenueParams> 
	extends PageConfig<PD,PP>
//	implements VenuePageConfigClass
{
	constructor(
		inBrowser:boolean,
		protected templates:Templates,
		protected build:BuildBasics,
		protected venue:VenueBasics
	) 
	{ 
		super(inBrowser,templates);
	}

	settings(): VenueAndPageSettingsType
	{
		let venueSettings: VenueSettingsType = {
			...this.venue.document.settings
		};

		/* Current page probably doesnt want definitions of all other pages. */
		delete (<any>venueSettings).implementations;

		return {
			venue: this.venue.document,

			...venueSettings,

			...this.defaultSettings(),

			/* add page settings as 'shortcuts'. warning: potential for name conflicts. */
			...this.venue.document.settings.implementations[this.name()]
		};
	}

	abstract defaultSettings(): PageSettingsType;
}

