import {css} from '@emotion/css';
import {SolidImageUploadWidget} from 'Browser/widgets/SolidImageUploadWidget';
import {ArtistUrls} from 'Common/ArtistUrls';
import {usePage} from 'Shared/artists/PageProvider';
import {Image, ImageSize, Url} from 'Shared/model/basic';
import {createSignal,createEffect,createMemo} from 'solid-js';

export function uploaderStyle()
{
	return css({
		display: 'flex',
		flexWrap: 'wrap',
		cursor: 'pointer',
		padding: 0,

		'.bf-imagePreview': {
			position: 'relative',
			display: 'inline-block'
		},

		'.bf-loading': {
			color: 'white',
			position: 'absolute',
			top: '50%',
			left:  '50%',
			transform: 'translate(-50%,-50%) scale(0.08)'
		}
	});
}

export function buttonsStyle()
{
//XXX spinner looks dodgy

	return css({
		'.bf-imageUploadButton': {
			position: 'relative',
			marginRight: 5,

			input: {
				position: 'absolute',
				top: 0,
				left: 0,
				bottom: 0,
				right: 0,
				opacity: 0,
				cursor: 'pointer',
			}
		},

		button: {
			cursor: 'pointer',
			padding: '4px 9px'
		},

		'.bf-removeImage:not([disabled])': {
			backgroundColor: '#ff738d',
			borderColor: '#ff5a78'
		},
	});
}

export function Label(props:any) 
{
//FIXME implement this? Or delete.

	return (
		<label>{props.labelText}
			<i class='info-pop fa fa-info-circle' 
				data-content='Use this field to upload a photo or image. Click the button to upload a file 
					from your computer, or if you&rsquo;re using a recent version of Chrome or Firefox, 
					you can simply drag&rsquo;n&rsquo;drop the file from your desktop or from a different browser window.<br>
					Alternatively, you can use a previously uploaded image. To select a previously uploaded file, 
					just type (part) of the file name in the input area, and it will be autocompleted.' 
				data-html='1' data-title='Add image' data-original-title='' title=''
			><span class='sr-only'>Info</span></i>
		</label>
	);
}

export interface IImageUpload extends Image {
	widget: SolidImageUploadWidget,
	fallbackUrl: Url;
} 

/*
	Options include 'ratio', 'minWidth' and 'minHeight' which are all optional. 
	If 'ratio' is supplied only one of either 'minWidth' and 'minHeight' are required to 
	enforce minimum dimensions.
*/
export function ImageUpload(props:IImageUpload)
{
	const config = props.widget.imageUploader.def;

	const [image,setImage] = createSignal(props as IImageUpload|undefined);
	const imageExists = createMemo(() => image()?.hash != undefined);

	const [anchor,setAnchor] = createSignal();
	createEffect(() => props.widget.init(anchor()));

	return (
		<div class={`${uploaderStyle}`} ref={setAnchor} id={props.id}
			on:Loading={e => setImage({dataUrl:e.detail.dataUrl, uploading:true})}
			on:Loaded={e => setImage({...e.detail, uploading:false}) }  
			on:Deleted={() => setImage(undefined) }
		>
			<input type='hidden' class='bf-value' value='' />
			<div class={buttonsStyle()}>
				<button type='button' class='bf-imageUploadButton'>
					<i class='fa fa-upload'></i> {imageExists() ? 'Replace' : 'Upload' } image
					<input type='file' accept=".jpg,.jpeg,.png" autocomplete='off' name='files[]' />
				</button>

				<button type='button' class='bf-removeImage' disabled={!imageExists()}>
					<i class='fa fa-times'></i> Remove image
				</button>

				<x-notes>
					<p>Drop PNG or JPEG file here <i>or</i> click to upload.</p>
					<p>Dimensions at least 1200&times;800px. Max file size is 10MB.</p>
				</x-notes>
			</div>

			<Preview {...image} exists={imageExists()} formats={props.sizes}
					assetFolder={config.assetFolder} size={config.previewSize} fallbackUrl={props.fallbackUrl}
			/>
		</div>
	);
}

export interface IPreview extends Image {
	dataUrl?:string;
	fallbackUrl:Url;
	exists:boolean;
	assetFolder:string;
	size:ImageSize;
	formats: Image;
} 

export function Preview(image:IPreview)
{
	const urls = new ArtistUrls(usePage().build);

	const loadingUrl = urls.resourceUrl('loading.gif');

	const previewUrl = () => {
		const size = image.formats[image.size];
		const useProduction = !image.dev;
		return urls.imageUrl(image.assetFolder,image.hash,image.size,size.format,useProduction);
	};


	// XXX bug - loading not centered properly 
	// XXX suppose we could use <Suspense> for the uploading part
	return (
		<x-pane class='bf-imagePreview'>
			{image.uploading ?
				<>
					<img class='bf-imagePreviewImg' alt='Image preview' src={image.dataUrl} />
					<img class='bf-loading' alt='Loading' src={loadingUrl} />
				</>
			:
				<img class='bf-imagePreviewImg' alt='Preview' 
					src={image.exists ? previewUrl() : urls.resourceUrl(image.fallbackUrl)}  /> 
			}
		</x-pane>
	);
}

