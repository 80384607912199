import {css} from '@emotion/css';
import Assert from 'Common/Assert';
import {Urls} from 'Common/Urls';
import {EditorOptions} from 'tinymce';

function style()
{
	return css({
		'.tox.tox': {
			'.tox-statusbar__branding': {
				svg: {
					fill: 'rgba(34,47,62,.5)',
					height: '0.9em',
					verticalAlign: '-0.1em',
					width: '3.6em'
				},

				a: {
					pointerEvents: 'none'
				}
			},

			'.tooManyCharacters': {
				color: 'red'
			},

			'.tox-promotion': {
				display:'none'
			}
		}
	});
}

export class SolidHtmlEditorWidget
{
	private loaded:boolean = false;

	constructor(
		private urls:Urls,
		private settings:EditorOptions
	)
	{
		this.loadTinyMce = this.loadTinyMce.bind(this);
		this.initTinyMceInstance = this.initTinyMceInstance.bind(this);
	}	

	init(anchorNode:HTMLElement)
	{
		setTimeout(async () => {
			await this.loadAndInitTinyMceInstance(anchorNode);
		},1);
	}

	private async loadAndInitTinyMceInstance(anchorNode:HTMLElement)
	{
		if (!this.loaded) {
			await this.loadTinyMce();
			this.loaded = true;
		}
		this.initTinyMceInstance(anchorNode);
	}

	private async loadTinyMce()
	{
		/*
			Webpack could be used instead. The main advantage of that would be to cut down the size of
			the distribution. Generally though I want access to everything and then use options to restrict
			the features as these options may change from place to place.
		 */
		/* Using dynamic import might be cleaner.  */

		await new Promise((resolve,reject) => {
			const script = document.createElement('script');
			script.id = 'tinymceScript';
			script.addEventListener('load',() => resolve(null),false);
			script.src = this.urls.buildUrl('resources/tinymce/tinymce.min.js');
			document.body.appendChild(script);
		});
	}

	private initTinyMceInstance(anchorNode:HTMLElement)
	{
		anchorNode.classList.add(style());

		const maxChars = this.settings.maxCharacters;

		/* Note that if used in a repeater the anchor ID might well be reused */
		this.destroy(anchorNode);

		const s = { ...this.settings,
			selector: '#'+this.escapeSelector('tinymce-'+anchorNode.id),
			setup: (editor:any) => {

				editor.on('Change', (e:any) => {
					(<any>anchorNode).value = editor.getContent();
					anchorNode.dispatchEvent(new CustomEvent('change'));
				});

				if (maxChars!=undefined) {
					editor.on('PostRender', (e:any) => {
						const rightContainer = Assert.have(editor.editorContainer.querySelector('.tox-statusbar__right-container'));
						const counterNode = document.createElement('span')
						counterNode.className = 'htmlEditorCounter';
						rightContainer.insertBefore(counterNode,rightContainer.firstChild);
					});
					editor.on('input', (e:any) => {
						const counterSpan = <HTMLSpanElement>Assert.htmlElement(editor.editorContainer.querySelector('.htmlEditorCounter'));
						this.characterMessage(counterSpan,editor.getContent().length,maxChars);
					});
					editor.on('LoadContent', (e:any) => {
						const counterSpan = <HTMLSpanElement>Assert.htmlElement(editor.editorContainer.querySelector('.htmlEditorCounter'));
						this.characterMessage(counterSpan,editor.getContent().length,maxChars);
					});
				}
			}
		};

		/* 
			HACKY. Multiple editors wont appear in Contacts > General without this.
			Perhaps the DOM-diffing is still modifying the DOM? Or perhaps multiple instances don't like
			initialising simultaneously.
			NB 1ms is not enough with so many editors on the page.
		 */
		setTimeout(() =>(<any>window).tinymce.init(s),100);
	}

	destroy(anchorNode:HTMLElement)
	{
		const id = (<any>window)?.tinymce?.get('tinymce-'+anchorNode.id);
		id?.remove();
		document.getElementById('tinymceScript')!.outerHTML = "";
	}

	private characterMessage(span:HTMLSpanElement,numChars:number,maxChars:number)
	{
		span.innerHTML = `${numChars} characters used (max ${maxChars})`;

		if (numChars <= maxChars)
			span.classList.remove('tooManyCharacters');
		else
			span.classList.add('tooManyCharacters');
	}

	private escapeSelector(s:string)
	{
		return s
			.replaceAll('{', '\\{')
			.replaceAll('}', '\\}')
			.replaceAll('[', '\\[')
			.replaceAll(']', '\\]')
			.replaceAll(',', '\\,')
			.replaceAll(':', '\\:');
	}
}

