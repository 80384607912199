import {Component} from 'Common/components/Component';
//TODO look at customising what is imported from Sortable - can be restricted 
import Sortable from 'sortablejs';
import {CreateArrayItemMessage, DeleteArrayItemMessage, ReorderArrayMessage} from 'Common/Messages';
import {IPage} from 'Common/pages/IPage';
import Assert from 'Common/Assert';
import {IConnectionToServer} from 'Common/IConnectionToServer';
import {EditArray, Location} from 'Common/config/PageConfigTypes';
import {Id} from 'Common/Id';
import {Document, IPageData} from 'Common/PageConfig';

/*
	EditableArray is really a tree of arrays, of dynamic height and breadth.

	'ownerId' is optional. If not present, it is assumed all rows belonging to the site are to be included.
 */
export class EditableArray<PageData extends IPageData,Doc extends Document> extends Component
{
    constructor(
		private server:IConnectionToServer,
		private page:IPage<PageData>,
		instanceName:string,
		readonly config: EditArray<PageData,Doc>,
		private documentData: Doc,
		private docId:Id,
		private localData:{current:Location} 
	)
    {
        super(instanceName);

		this.deleteItem = this.deleteItem.bind(this);
		this.addItemAndOpen = this.addItemAndOpen.bind(this);
		this.toggleItem = this.toggleItem.bind(this);
	}

    componentType() { return 'editArray'; }

	async load()
	{
		return {current:[]};
	}

    toggleItem(location:Location)
    {
		/*	
			ALGORITHM: 
			1. Find common prefix
			2. If there is more in location, use the new location
			3. Else use the parent location (ie toggle close)

			EXAMPLES:

			Existing: 3      location: 3,5    ACTION: Set to 3,5 
			Existing: 3,5    location: 3      ACTION: Set to [] - close 3
			Existing: 3,5,1  location: 3,5    ACTION: Set to 3  - close 3,5
			Existing: 3,5,1  location: 3,6    ACTION: Set to 3,6 
			Existing: 3,5,1  location: 3,5,2  ACTION: Set to 3,5,2 

		*/
		const current = this.localData.current ?? [];
		let i = 0;
		while (i<location.length && i<current.length)  {
			if (location[i] != current[i])
				break;
			i++;
		}

		this.localData.current = location.length > i ? location : location.slice(0,i-1);
    }

	async deleteItem(parentLocation:Location,index:number)
	{
		const msg = new DeleteArrayItemMessage(this.page.name(),this.name,this.docId,parentLocation,index);

		if (this.config.redirect!=undefined) {
			await this.server.sendOperation(msg)
			location.href = this.config.redirect(parentLocation,this.page.data);
			return;
		}

		this.server.sendOperationOptimistically(msg)

		const parent:any = this.config.locateListParent(this.documentData,parentLocation);
		const field = this.config.fieldName(parentLocation);
		parent[field][index] = undefined;
		parent[field].splice(index,1);

		this.localData.current = parentLocation;
	}

	async addItem(location:Location,wait:boolean)
	{
		const initial:any = this.config.initialValues!=undefined ? this.config.initialValues(location,this.page.data) : {}; 

		const msg = new CreateArrayItemMessage(this.page.name(),this.name,this.docId,location,initial);

		if (wait)
        	await this.server.sendOperation(msg);
		else
        	this.server.sendOperationOptimistically(msg);

		const parent:any = this.config.locateListParent(this.documentData,location);
		const field = this.config.fieldName(location);

		if (parent[field]==undefined)
			parent[field] = [];
		parent[field].push(initial);
	}

	async addItemAndOpen(location:Location)
	{
		this.addItem(location,false);
		const parent:any = this.config.locateListParent(this.documentData,location);
		const field = this.config.fieldName(location);

    	this.toggleItem([...location,parent[field].length - 1]);
	}

    postDisplay()
    {
//XXX move back to a jsWidget? Note it uses a browser-only library, and this file is in common/

		for (const el of document.querySelectorAll(this.config.selector)) {
			/* Due to tabs etc the component may not present */
			if (el==undefined)
				return;
			const element = Assert.htmlElement(el);

			const sortable: Sortable & {locations?: {[row:number]:Location}}  = new Sortable(element, {
				handle: '.sortableItem',
				draggable: '.sortableItem',
				onStart: async e => {
					const location:{[row:number]:Location} = {};
					let i = 0;
					for (const item of e.target.children) {
						if (item.classList.contains('sortableItem'))
							location[i] = JSON.parse(Assert.htmlElement(item).dataset.location!);
						i++;
					}
					sortable['locations'] = location;
				},
				onEnd: async e => {
					const locations = Assert.have(sortable['locations']);
					const oldIndex = Assert.toNumber(locations[e.oldIndex!].slice(-1)[0]); 
					const newIndex = Assert.toNumber(locations[e.newIndex!].slice(-1)[0]);
					const parentLocation = locations[e.oldIndex!].slice(0,-1);

					const msg = new ReorderArrayMessage(this.page.name(),this.name,this.docId,parentLocation,oldIndex,newIndex);
					this.server.sendOperationOptimistically(msg);

					const parent:any = this.config.locateListParent(this.documentData,parentLocation);
					const field = this.config.fieldName(parentLocation);

					const element = parent[field][oldIndex];
					parent[field].splice(oldIndex,1);
					parent[field].splice(newIndex,0,element);

					this.localData.current = parentLocation;

					//XXX need to update the data.indexes for further sorting.
					//    Nasty referring to the browser here - cf separating out an associated widget.
					if (window!=undefined)
						window.pageWrapper.refresh();
				}
			});
		}
    }
}  

