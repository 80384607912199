import { z } from 'zod';
import * as t from 'Shared/model/basic';

//slider if used outside of vertical sections
export const slider = z.object({
	caption: z.string(),
	url: t.url
});

//used for slider inside vertical sections/showcase
export const carouselItem = z.object({
	image: t.image,
	imageCaption: z.string(),
//CJ had to write item prefix on fields because display was clashing with top level showcase fields of sameName
	itemTeaser: z.string(),
	itemTitle: z.string(),
	itemTagline: z.string(),
	itemDetails: z.string(),
	itemLink: t.url,
	itemLinkType: z.string(),
	itemLinkText: z.string(),
	addItemDetails:z.boolean(),
	addItemLink:z.boolean(),

});


export const showcase = z.object({
	sectionInternalName: z.string(),
	layout: z.enum(['carousel','noImage','imageRight','imageLeft','imageAbove','imageBelow','imageAsBackground','gallery','slideshow','carouselAllEvents','carouselMusic','carouselNonMusic','carouselSpecials']),
	teaser: z.string(),
	title: z.string(),
	tagline: z.string(),
	details: z.string(),
	image: t.image,
	link: t.url,
	linkType: z.string(),
	linkText: z.string(),
	addDetails:z.boolean(),
	addLink:z.boolean(),
	items: z.array(carouselItem)
});


export const home = z.object({
	_id: t.id,
	siteId: t.id,
	banner: z.string(),
	slider: z.array(showcase),
	eventCarouselSize: z.number(),
	showcase: z.array(showcase),
})
.partial().required({
	_id: true,
	siteId: true,
	slider: true,
	showcase: true
});

export type Home = z.infer<typeof home>;

